/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Link } from 'react-router-dom';
import moment from 'moment';

export default function EventList({ data }) {
  return (
    <section className='upcoming-events-area events-page'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='upcoming-events-item'>
              {data &&
                data.length > 0 &&
                data.map((d) => (
                  <div className='item mt-20' key={d._id}>
                    <div className='row align-items-center'>
                      <div className='col-lg-9'>
                        <div className='upcoming-events-content d-block d-md-flex align-items-center'>
                          <div
                            className='thumb'
                            // css={{ maxWidth: '350px', minWidth: '350px' }}
                          >
                            <img src={d.cover} alt={d.title} />
                          </div>
                          <div className='content ml-65'>
                            <ul>
                              <li>
                                <i className='fa fa-calendar-o'></i>{' '}
                                {moment(d.eventStart).format('DD/MMM/YYYY')}{' '}
                              </li>
                              <li>
                                <i className='fa fa-clock-o'></i>{' '}
                                {moment(d.eventStart).format('hh:mm a')}{' '}
                              </li>
                              <li>
                                <i className='fa fa-clock-o'></i>{' '}
                                {moment(d.eventEnd).format('hh:mm a')}{' '}
                              </li>
                            </ul>
                            <h4 className='title'>{d.title}</h4>
                            <p>{d.description}</p>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-3'>
                        <div className='events-btn text-left text-lg-right'>
                          <Link
                            className='main-btn main-btn-2'
                            to={`/events/${d._id}`}
                          >
                            Join event
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

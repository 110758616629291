import React, { useState } from 'react';
import Lightbox from 'react-awesome-lightbox';
import 'react-awesome-lightbox/build/style.css';

export default ({ data }) => {
  const [gallery, setGallery] = useState(false);
  const [index, setIndex] = useState(0);
  return (
    <div className='gallery-area gallery-page'>
      <div className='container'>
        <div className='gallery-itmes'>
          {data && data.length > 0 && (
            <div className='row align-items-center'>
              {data.map((d, i) => (
                <div
                  className='col-lg-4 col-md-6 col-sm-6'
                  key={i}
                  onClick={() => {
                    setGallery(true);
                    setIndex(i);
                  }}
                >
                  <div className='gallery-thumb mt-30'>
                    <img src={d} alt={i} />
                  </div>
                </div>
              ))}
            </div>
          )}
          {gallery && (
            <Lightbox
              images={data}
              startIndex={index}
              onClose={() => setGallery(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

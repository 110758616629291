import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default ({ title, links = [], url = 'https://images.unsplash.com/photo-1594125674965-70d796b6693f?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2850&q=80', breadcrumbs = true }) => {
  const { setting } = useSelector(({ settings }) => settings);
  return (
    <div className="page-title-area bg_cover" style={{ backgroundImage: `url('${setting && setting.defaultHeader ? setting.defaultHeader : url}')` }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="page-title-content">
              <h3 className="title">{title}</h3>
              {breadcrumbs && links && links.length > 0 && <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  {links.map((link, index) => (
                    <li key={index} className={`breadcrumb-item ${index === links.length - 1 && 'active'}`}>
                      <Link to={link.url ? link.url : "/"}>{link.title}</Link>
                    </li>
                  ))}
                </ol>
              </nav>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
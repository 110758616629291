import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getBranch } from '../../redux/actions';
import { PRIMARY_COLOR } from '../../constants';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import Avatar from '@material-ui/core/Avatar';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import BusinessIcon from '@material-ui/icons/Business';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

function ConfirmationDialog(props) {
  const { onClose, branch, open, branches, ...other } = props;
  const [value, setValue] = useState('');
  const radioGroupRef = useRef(null);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth='xs'
      scroll={'paper'}
      onEntering={handleEntering}
      aria-labelledby='branch-selector-dialog-title'
      open={open}
      {...other}
    >
      <DialogTitle id='branc-selector-dialog-title'>Select Branch</DialogTitle>
      {branches && (
        <DialogContent dividers style={{ maxHeight: 250 }}>
          <RadioGroup
            ref={radioGroupRef}
            aria-label='branch'
            name='branch'
            value={value ? value : branch}
            onChange={handleChange}
          >
            {branches.map((option) => (
              <FormControlLabel
                value={option._id}
                key={option._id}
                control={<Radio />}
                label={option.name}
              />
            ))}
          </RadioGroup>
        </DialogContent>
      )}
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color='primary'>
          Cancel
        </Button>
        <Button
          onClick={handleOk}
          disabled={!branches && !value}
          color='primary'
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles(() => ({
  paper: {
    width: '100%',
  },
  button: {
    borderColor: PRIMARY_COLOR,
    color: PRIMARY_COLOR,
    textTransform: 'none',
  },
}));

export default function BranchSelector({ variant = 'text' }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { branches, branch } = useSelector(({ settings }) => settings);

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleClose = (newValue) => {
    setOpen(false);
    if (newValue) {
      dispatch(getBranch(newValue));
    }
  };

  return (
    <>
      <Button
        size='small'
        className={classes.button}
        variant={variant}
        startIcon={
          branch ? (
            <Avatar
              alt={branch ? branch.name : ''}
              src={branch ? branch.logo : ''}
              style={{
                width: 32,
                height: 32,
              }}
              imgProps={{
                style: {
                  backgroundColor: 'transparent',
                  objectFit: 'contain',
                },
              }}
            />
          ) : (
            <Avatar
              alt={'Select Branch'}
              style={{ width: 32, height: 32, backgroundColor: 'transparent' }}
            >
              <BusinessIcon color='primary' />
            </Avatar>
          )
        }
        endIcon={<ArrowDropDownIcon />}
        onClick={handleClickListItem}
      >
        {branch ? branch.name : 'Select Branch'}
      </Button>
      <ConfirmationDialog
        classes={{
          paper: classes.paper,
        }}
        id='branch'
        keepMounted
        open={open}
        branches={branches}
        branch={branch ? branch._id : ''}
        onClose={handleClose}
      />
    </>
  );
}

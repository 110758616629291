import {
  GETBLOG_ATTEMPT,
  GETBLOG_SUCCESS,
  GETBLOG_FAIL,
  GETPOST_ATTEMPT,
  GETPOST_SUCCESS,
  GETPOST_FAIL,
  GETPOSTCATEGORIES_ATTEMPT,
  GETPOSTCATEGORIES_SUCCESS,
  GETPOSTCATEGORIES_FAIL,
} from '../constants';

const initialState = {
  posts: null,
  post: null,
  categories: null,
  loading: false,
  error: null,
};

export default function homeReducer(state = initialState, action) {
  switch (action.type) {
    case GETBLOG_ATTEMPT:
      return { ...state, loading: true, posts: null };
    case GETBLOG_SUCCESS:
      return { ...state, loading: false, posts: action.payload };
    case GETBLOG_FAIL:
      return { ...state, loading: false, error: action.payload };
    case GETPOST_ATTEMPT:
      return { ...state, loading: true };
    case GETPOST_SUCCESS:
      return { ...state, loading: false, post: action.payload };
    case GETPOST_FAIL:
      return { ...state, loading: false, error: action.payload };
    case GETPOSTCATEGORIES_ATTEMPT:
      return { ...state, loading: true };
    case GETPOSTCATEGORIES_SUCCESS:
      return { ...state, loading: false, categories: action.payload };
    case GETPOSTCATEGORIES_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}

/** @jsx jsx */
import { Link, useRouteMatch } from 'react-router-dom';
import { jsx } from '@emotion/core';
import { SocialLinks } from '../../Footer';

export default function ({ menu, setting, menuState, setMenuState }) {
  return (
    <div className='offcanvas_menu'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12'>
            <div className={'offcanvas_menu_wrapper ' + menuState}>
              <div className='canvas_close' onClick={() => setMenuState('')}>
                <i className='fa fa-times'></i>
              </div>
              <div className='offcanvas-social'>
                {setting && <SocialLinks setting={setting} />}
              </div>
              <div id='menu' className='text-left '>
                {menu && menu.length > 0 && (
                  <ul className='offcanvas_main_menu'>
                    {menu.map((m, i) => (
                      <li
                        key={i}
                        className={
                          m.submenu
                            ? 'menu-item-has-children ' + useRouteMatch(m.link)
                              ? 'active'
                              : null
                            : null
                        }
                      >
                        <Link to={m.link} onClick={() => setMenuState(false)}>
                          {m.name}
                        </Link>
                        {m.submenu && m.submenu.length > 0 && (
                          <ul className='sub-menu'>
                            {m.submenu.map((sub, j) => (
                              <li key={j}>
                                <Link
                                  to={sub.link}
                                  onClick={() => setMenuState(false)}
                                >
                                  {sub.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className='offcanvas_footer'>
                <span>
                  <a href='mailto:info@popcornstudio.co'>
                    <i className='fa fa-envelope-o'></i> info@popcornstudio.co
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { ANALYTICS_ID, PIXEL_ID } from './constants';

export const initGA = () => {
  ReactGA.initialize(ANALYTICS_ID, {
    gaOptions: { cookieFlags: 'max-age=7200;secure;samesite=popcornstudio.co' },
  });
};

const initPixel = () => {
  ReactPixel.init(
    PIXEL_ID,
    {},
    {
      debug: process.env.NODE_ENV === 'development',
      autoConfig: false,
    }
  );
};

export const GApageView = (page) => {
  ReactGA.pageview(page);
};

export const GAmodalView = (modal) => {
  ReactGA.modalview(modal);
};

export const GAevent = (categoryName, eventName) => {
  ReactGA.event({
    category: categoryName, // Required
    action: eventName, // Required
    label: 'labelName',
    value: 10,
    nonInteraction: false,
  });
};

export const GAtiming = (categoryName, variableName, valueNum) => {
  ReactGA.timing({
    category: categoryName,
    variable: variableName,
    value: valueNum,
  });
};

export const GAexception = (detail) => {
  ReactGA.exception({ description: detail });
};

initGA();
if (PIXEL_ID) {
  initPixel();
}

export function withTracker(WrappedComponent, options = {}) {
  const trackPage = (page) => {
    ReactGA.set({
      page,
      ...options,
    });
    ReactGA.pageview(page);
    if (PIXEL_ID) {
      ReactPixel.pageView();
    }
  };
  const HOC = (props) => {
    useEffect(
      () => trackPage(props.location.pathname),
      [props.location.pathname]
    );
    return <WrappedComponent {...props} />;
  };
  return HOC;
}

/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Link } from 'react-router-dom';
import { mqMax } from '../../constants';

export const SocialLinks = ({ setting }) => {
  return (
    <ul css={{ [mqMax[0]]: { margin: '15px 0px' } }}>
      {setting?.facebook && (
        <li>
          <a
            href={`https://facebook.com/${setting?.facebook}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <i className='fa fa-facebook-square'></i>
          </a>
        </li>
      )}
      {setting?.twitter && (
        <li>
          <a
            href={`https://twitter.com/${setting?.twitter}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <i className='fa fa-twitter'></i>
          </a>
        </li>
      )}
      {setting?.instagram && (
        <li>
          <a
            href={`https://instagram.com/${setting?.instagram}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <i className='fa fa-instagram'></i>
          </a>
        </li>
      )}
      {setting?.linkedin && (
        <li>
          <a
            href={`https://linkedin.com/company/${setting?.linkedin}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <i className='fa fa-linkedin'></i>
          </a>
        </li>
      )}
      {setting?.youtube && (
        <li>
          <a
            href={`https://youtube.com/channel/${setting?.youtube}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <i className='fa fa-youtube-play'></i>
          </a>
        </li>
      )}
    </ul>
  );
};

export default function ({
  footer1,
  footer2,
  footer3,
  setting,
  pattern = false,
}) {
  return (
    <section className='footer-area'>
      <div className='container'>
        <div className='row'>
          {setting && (
            <div className='col-lg-3 col-md-6 col-sm-8'>
              <div className='footer-about mt-30'>
                <div className='logo'>
                  <Link to='/'>
                    <img src={setting.darkLogo} alt='logo' height='50' />
                  </Link>
                </div>
                <ul className='my-3'>
                  <li>
                    <a href={`mailto:${setting.email}`}>{setting.email}</a>
                  </li>
                  <li>
                    <a
                      href={
                        setting?.whatsApp || `https://wa.me/${setting.phone}`
                      }
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <i className='fa fa-whatsapp mr-1' /> {setting.phone}
                    </a>
                  </li>
                </ul>
                <div className='d-flex flex-column' css={{ gap: '1rem' }}>
                  {setting?.address && (
                    <small className='text-white' css={{ fontSize: 14 }}>
                      <span className='text-white font-weight-bold mr-1'>
                        Head Office:
                      </span>{' '}
                      {setting?.address}
                    </small>
                  )}
                  <small className='text-white' css={{ fontSize: 14 }}>
                    <span className='text-white font-weight-bold mr-1'>
                      Office Hours:
                    </span>{' '}
                    Monday to Saturday <br /> 9:00 AM to 7:00 PM
                  </small>
                </div>
              </div>
            </div>
          )}
          {footer1 && (
            <div className='col-lg-3 col-md-6 col-sm-6'>
              <div className='footer-list mt-30'>
                <h4 className='title'>{footer1.name}</h4>
                {footer1.links && footer1.links.length > 0 && (
                  <ul>
                    {footer1.links.map((m, i) => (
                      <li key={i}>
                        <Link to={m.link}>{m.name}</Link>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          )}
          {footer2 && (
            <div className='col-lg-3 col-md-6 col-sm-6'>
              <div className='footer-list mt-30'>
                <h4 className='title'>{footer2.name}</h4>
                {footer2.links && footer2.links.length > 0 && (
                  <ul>
                    {footer2.links.map((m, i) => (
                      <li key={i}>
                        <Link to={m.link}>{m.name}</Link>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          )}
          {footer3 && (
            <div className='col-lg-3 col-md-6 col-sm-6'>
              <div className='footer-list mt-30'>
                <h4 className='title'>{footer3.name}</h4>
                {footer3.links && footer3.links.length > 0 && (
                  <ul>
                    {footer3.links.map((m, i) => (
                      <li key={i}>
                        <Link to={m.link}>{m.name}</Link>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='footer-copyright-area'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='footer-copyright d-block d-md-flex justify-content-between align-items-center'>
                <small className='text-white' css={{ fontSize: 10 }}>
                  Developed with{' '}
                  <span role='img' aria-label='heart'>
                    ❤️
                  </span>{' '}
                  by{' '}
                  <a
                    href='https://chumchum.agency'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Chum Chum Digital Agency (Pvt) Ltd
                  </a>
                </small>
                <small
                  className='text-white'
                  css={{
                    fontSize: 12,
                    fontWeight: 300,
                    letterSpacing: 'normal',
                  }}
                >
                  © {new Date().getFullYear()} Copyrights. All rights reserved.
                </small>
                {setting && <SocialLinks setting={setting} />}
              </div>
            </div>
          </div>
        </div>
      </div>
      {pattern && (
        <div className='footer-pattern'>
          <img src='/assets/images/footer-pattern.png' alt='' />
        </div>
      )}
    </section>
  );
}

import React from 'react';
import { Link } from 'react-router-dom';

export default function ({ title = 'PopCorn is changing the way people think', link = '/branches', image = 'https://images.unsplash.com/photo-1594125674965-70d796b6693f?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2850&q=80' }) {
  return (
    <section className="codesk-changing-area bg_cover" style={{ backgroundImage: `url('${image}')` }}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-7">
            <div className="codesk-changing-text">
              <h3 className="title">{title}</h3>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="codesk-changing-play text-left text-lg-right">
              <Link className="main-btn video-popup" to={link}><i className="fa fa-play"></i></Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export const SIGNIN_ATTEMPT = 'SIGNIN_ATTEMPT';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_FAIL = 'SIGNIN_FAIL';

export const SIGNUP_ATTEMPT = 'SIGNUP_ATTEMPT';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';

export const FETCHUSER_ATTEMPT = 'FETCHUSER_ATTEMPT';
export const FETCHUSER_SUCCESS = 'FETCHUSER_SUCCESS';
export const FETCHUSER_FAIL = 'FETCHUSER_FAIL';

export const SUBMITFORM_ATTEMPT = 'SUBMITFORM_ATTEMPT';
export const SUBMITFORM_SUCCESS = 'SUBMITFORM_SUCCESS';
export const SUBMITFORM_FAIL = 'SUBMITFORM_FAIL';

export const GETSETTING_ATTEMPT = 'GETSETTING_ATTEMPT';
export const GETSETTING_SUCCESS = 'GETSETTING_SUCCESS';
export const GETSETTING_FAIL = 'GETSETTING_FAIL';

export const GETMENU_ATTEMPT = 'GETMENU_ATTEMPT';
export const GETMENU_SUCCESS = 'GETMENU_SUCCESS';
export const GETMENU_FAIL = 'GETMENU_FAIL';

export const GETSLIDES_ATTEMPT = 'GETSLIDES_ATTEMPT';
export const GETSLIDES_SUCCESS = 'GETSLIDES_SUCCESS';
export const GETSLIDES_FAIL = 'GETSLIDES_FAIL';

export const GETBRANCHES_ATTEMPT = 'GETBRANCHES_ATTEMPT';
export const GETBRANCHES_SUCCESS = 'GETBRANCHES_SUCCESS';
export const GETBRANCHES_FAIL = 'GETBRANCHES_FAIL';

export const GETBRANCH_ATTEMPT = 'GETBRANCH_ATTEMPT';
export const GETBRANCH_SUCCESS = 'GETBRANCH_SUCCESS';
export const GETBRANCH_FAIL = 'GETBRANCH_FAIL';

export const CLEARBRANCH = 'CLEARBRANCH';

export const GETEVENTS_ATTEMPT = 'GETEVENTS_ATTEMPT';
export const GETEVENTS_SUCCESS = 'GETEVENTS_SUCCESS';
export const GETEVENTS_FAIL = 'GETEVENTS_FAIL';

export const GETEVENT_ATTEMPT = 'GETEVENT_ATTEMPT';
export const GETEVENT_SUCCESS = 'GETEVENT_SUCCESS';
export const GETEVENT_FAIL = 'GETEVENT_FAIL';

export const GETBLOG_ATTEMPT = 'GETBLOG_ATTEMPT';
export const GETBLOG_SUCCESS = 'GETBLOG_SUCCESS';
export const GETBLOG_FAIL = 'GETBLOG_FAIL';

export const GETPOST_ATTEMPT = 'GETPOST_ATTEMPT';
export const GETPOST_SUCCESS = 'GETPOST_SUCCESS';
export const GETPOST_FAIL = 'GETPOST_FAIL';

export const GETGENERALOFFERINGS_ATTEMPT = 'GETGENERALOFFERINGS_ATTEMPT';
export const GETGENERALOFFERINGS_SUCCESS = 'GETGENERALOFFERINGS_SUCCESS';
export const GETGENERALOFFERINGS_FAIL = 'GETGENERALOFFERINGS_FAIL';

export const GETBRANCHOFFERINGS_ATTEMPT = 'GETBRANCHOFFERINGS_ATTEMPT';
export const GETBRANCHOFFERINGS_SUCCESS = 'GETBRANCHOFFERINGS_SUCCESS';
export const GETBRANCHOFFERINGS_FAIL = 'GETBRANCHOFFERINGS_FAIL';

export const GETFEATURES_ATTEMPT = 'GETFEATURES_ATTEMPT';
export const GETFEATURES_SUCCESS = 'GETFEATURES_SUCCESS';
export const GETFEATURES_FAIL = 'GETFEATURES_FAIL';

export const GETTESTIMONIALS_ATTEMPT = 'GETTESTIMONIALS_ATTEMPT';
export const GETTESTIMONIALS_SUCCESS = 'GETTESTIMONIALS_SUCCESS';
export const GETTESTIMONIALS_FAIL = 'GETTESTIMONIALS_FAIL';

export const SENDINQUIRY_ATTEMPT = 'SENDINQUIRY_ATTEMPT';
export const SENDINQUIRY_SUCCESS = 'SENDINQUIRY_SUCCESS';
export const SENDINQUIRY_FAIL = 'SENDINQUIRY_FAIL';

export const GETMEETINGSLOTS_ATTEMPT = 'GETMEETINGSLOTS_ATTEMPT';
export const GETMEETINGSLOTS_SUCCESS = 'GETMEETINGSLOTS_SUCCESS';
export const GETMEETINGSLOTS_FAIL = 'GETMEETINGSLOTS_FAIL';

export const GETEVENTSLOTS_ATTEMPT = 'GETEVENTSLOTS_ATTEMPT';
export const GETEVENTSLOTS_SUCCESS = 'GETEVENTSLOTS_SUCCESS';
export const GETEVENTSLOTS_FAIL = 'GETEVENTSLOTS_FAIL';

export const CLEARALERT = 'CLEARALERT';

export const BOOKMEETING_ATTEMPT = 'BOOKMEETING_ATTEMPT';
export const BOOKMEETING_SUCCESS = 'BOOKMEETING_SUCCESS';
export const BOOKMEETING_FAIL = 'BOOKMEETING_FAIL';

export const BOOKEVENT_ATTEMPT = 'BOOKEVENT_ATTEMPT';
export const BOOKEVENT_SUCCESS = 'BOOKEVENT_SUCCESS';
export const BOOKEVENT_FAIL = 'BOOKEVENT_FAIL';

export const RESETBOOKING = 'RESETBOOKING';

export const GETCITIES_ATTEMPT = 'GETCITIES_ATTEMPT';
export const GETCITIES_SUCCESS = 'GETCITIES_SUCCESS';
export const GETCITIES_FAIL = 'GETCITIES_FAIL';

export const GETPOSTCATEGORIES_ATTEMPT = 'GETPOSTCATEGORIES_ATTEMPT';
export const GETPOSTCATEGORIES_SUCCESS = 'GETPOSTCATEGORIES_SUCCESS';
export const GETPOSTCATEGORIES_FAIL = 'GETPOSTCATEGORIES_FAIL';
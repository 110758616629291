import {
  GETSLIDES_ATTEMPT,
  GETSLIDES_SUCCESS,
  GETSLIDES_FAIL,
  GETGENERALOFFERINGS_ATTEMPT,
  GETGENERALOFFERINGS_SUCCESS,
  GETGENERALOFFERINGS_FAIL,
  GETFEATURES_ATTEMPT,
  GETFEATURES_SUCCESS,
  GETFEATURES_FAIL,
  GETTESTIMONIALS_ATTEMPT,
  GETTESTIMONIALS_SUCCESS,
  GETTESTIMONIALS_FAIL,
  SENDINQUIRY_ATTEMPT,
  SENDINQUIRY_SUCCESS,
  SENDINQUIRY_FAIL,
} from '../constants';


const initialState = {
  slides: null,
  testimonials: null,
  ammenities: null,
  loading: false,
  error: null,
  offerings: null,
  response: null
};

export default function homeReducer(state = initialState, action) {
  switch (action.type) {
    case GETSLIDES_ATTEMPT:
      return { ...state, loading: true, slides: null };
    case GETSLIDES_SUCCESS:
      return { ...state, loading: false, slides: action.payload };
    case GETSLIDES_FAIL:
      return { ...state, loading: false, error: action.payload };
    case GETGENERALOFFERINGS_ATTEMPT:
      return { ...state, loading: true };
    case GETGENERALOFFERINGS_SUCCESS:
      return { ...state, loading: false, offerings: action.payload };
    case GETGENERALOFFERINGS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case GETFEATURES_ATTEMPT:
      return { ...state, loading: true };
    case GETFEATURES_SUCCESS:
      return { ...state, loading: false, ammenities: action.payload };
    case GETFEATURES_FAIL:
      return { ...state, loading: false, error: action.payload };
    case GETTESTIMONIALS_ATTEMPT:
      return { ...state, loading: true };
    case GETTESTIMONIALS_SUCCESS:
      return { ...state, loading: false, testimonials: action.payload };
    case GETTESTIMONIALS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case SENDINQUIRY_ATTEMPT:
      return { ...state, loading: true, response: null };
    case SENDINQUIRY_SUCCESS:
      return { ...state, loading: false, response: action.payload.message };
    case SENDINQUIRY_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}

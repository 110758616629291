/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState } from 'react';
import BookTourDialog from '../BookTourDialog';

export default ({ data, title = 'PopCorn', pattern = false }) => {
  const [bookDialog, setBookDialog] = useState(false);
  const [offering, setOffering] = useState(null);
  return (
    <section className='explore-codesk-area'>
      <div className='container'>
        {title && (
          <div className='row'>
            <div className='col-lg-12'>
              <div className='explore-codesk-title text-center'>
                <h3 className='title'>Explore {title}</h3>
              </div>
            </div>
          </div>
        )}
        {data && data.length > 0 && (
          <div className='row'>
            {data.map((d) => (
              <div className='col-lg-6' key={d._id}>
                <div className='explore-codesk-item mt-50'>
                  <img
                    src={d.media}
                    alt='explore'
                    height={313}
                    width={213}
                    style={{ objectFit: 'contain' }}
                  />
                  <h3 className='title'>{d.title}</h3>
                  <p
                    className='description text-truncate'
                    style={{ fontSize: 12 }}
                  >
                    {d.description}
                  </p>
                  <h5 className='py-3'>{d.rightCaption}</h5>
                  <div className='item d-flex justify-content-center justify-content-sm-start align-items-center'>
                    <p>{d.leftCaption}</p>
                  </div>
                  <button
                    className='main-btn main-btn-2 text-white'
                    css={{
                      lineHeight: '36px',
                      padding: '0 20px',
                      fontSize: '14px',
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      setOffering(d._id);
                      setBookDialog(true);
                    }}
                  >
                    Book Now <i className='flaticon-right-arrow'></i>
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {pattern && (
        <div className='explore-codesk-shape'>
          <img src='/assets/images/testimonial-pattern.png' alt='' />
        </div>
      )}
      <BookTourDialog
        open={bookDialog}
        onClose={() => setBookDialog(false)}
        initial={offering}
      />
    </section>
  );
};

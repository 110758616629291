import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getBranchOfferings } from '../../redux/actions';
import PageHeader from '../../components/PageHeader';
import GalleryGrid from '../../components/GalleryGrid';
import Ammenities from '../../components/Ammenities';
import Pricing from '../../components/Pricing';
import Plans from '../../components/Plans';
import Loader from '../../components/Loader';
import ChoosePricingPlan from '../../components/Pricing/choose';

function Branches() {
  const dispatch = useDispatch();
  const { branch, offerings, loading } = useSelector(
    ({ settings }) => settings
  );
  useEffect(() => {
    if (branch !== null) {
      dispatch(getBranchOfferings(branch._id));
    }
  }, [branch, dispatch]);
  return (
    <React.Fragment>
      {loading && <Loader />}
      <PageHeader title='Branches' />
      {branch && (
        <Ammenities
          title={branch.name}
          description={branch.description}
          counter={branch.counter}
          features={branch.features}
        />
      )}
      {branch && <ChoosePricingPlan />}
      {branch && offerings && offerings.length > 0 && (
        <Plans data={offerings} title={branch.name} />
      )}
      <Pricing enablePricingPlan={false} />
      {branch && branch.sliderImages && branch.sliderImages.length > 0 && (
        <GalleryGrid data={branch.sliderImages.filter((x) => x !== '')} />
      )}
    </React.Fragment>
  );
}

export default Branches;

import { createMuiTheme } from '@material-ui/core/styles';
import { PRIMARY_COLOR, SECONDARY_COLOR } from './constants';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR,
      light: SECONDARY_COLOR,
      dark: PRIMARY_COLOR,
    },
  },
  typography: {
    fontFamily: 'Altone Trial, sans-serif;',
  },
});

export default theme;

/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPosts } from '../../redux/actions';
import { useParams, Link, useHistory } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import Loader from '../../components/Loader';

function Blog() {
  let { category } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { posts, loading } = useSelector(({ blog }) => blog);

  useEffect(() => {
    dispatch(getPosts());
  }, [dispatch]);
  return (
    <React.Fragment>
      <PageHeader title='Blog' links={[{ title: 'Home' }, { title: 'Blog' }]} />
      {loading && <Loader />}
      <section className='news-2-area news-page'>
        {posts && posts.length > 0 && (
          <div className='container'>
            <div className='row justify-content-center'>
              {posts
                .filter((x) => (category ? x.category.slug === category : x))
                .map((post, index) => (
                  <div className='col-lg-4 col-md-7 col-sm-9' key={index}>
                    <div className='news-item mt-30'>
                      <div className='news-thumb' css={{ cursor: 'pointer' }}>
                        <img
                          css={{
                            height: '300px',
                            width: '100%',
                            objectFit: 'cover',
                          }}
                          src={post.cover}
                          alt={`cover-${index}`}
                          onClick={() =>
                            history.push(
                              `/blog/${post.category.slug}/${post.slug}`
                            )
                          }
                        />
                      </div>
                      <div className='news-content'>
                        <ul>
                          <li>
                            <i className='fa fa-user-o'></i> by{' '}
                            {post.author.name}
                          </li>
                          <li>
                            <i className='fa fa-clock-o'></i>{' '}
                            {new Date(post.createdAt).toLocaleDateString()}
                          </li>
                        </ul>
                        <h3 className='title'>{post.title}</h3>
                        <Link to={`/blog/${post.category.slug}/${post.slug}`}>
                          <i className='flaticon-right-arrow'></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
      </section>
    </React.Fragment>
  );
}

export default Blog;

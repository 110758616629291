import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getEvent } from '../../redux/actions';
import Loader from '../../components/Loader';
import moment from 'moment';

import PageHeader from '../../components/PageHeader';

function Events() {
  let { id } = useParams();
  const dispatch = useDispatch();
  const { event, loading } = useSelector(({ settings }) => settings);
  useEffect(() => {
    dispatch(getEvent(id));
  }, [dispatch, id]);
  return (
    <React.Fragment>
      {loading && <Loader />}
      {event && (
        <>
          <PageHeader
            title='Events'
            links={[
              { title: 'Home' },
              { title: 'Events', url: '/events' },
              { title: event.title },
            ]}
          />
          <section className='event-details-area pb-90'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-6'>
                  <div className='event-details-content'>
                    <h3 className='title'>{event.title}</h3>
                    <p>{event.description}</p>
                  </div>
                </div>
                <div className='col-lg-6'>
                  <div className='event-details-thumb'>
                    <img src={event.cover} alt={event.title} />
                  </div>
                </div>
              </div>
              {event.link && (
                <div className='social'>
                  <ul>
                    <li>
                      <a
                        href={event.link}
                        className='btn btn-outline btn-rounded btn-primary text-white'
                      >
                        <i className='fa fa-external-link'></i> Get Tickets
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </section>
          {event.branch && (
            <section className='event-info-area'>
              <div className='container'>
                <div className='row justify-content-center'>
                  <div className='col-lg-4 col-md-7'>
                    <div className='event-info-item mt-30'>
                      <h3 className='title'>Venue</h3>
                      <p className='text-white font-weight-bold'>
                        {event.branch.name}
                      </p>
                      <div className='info'>
                        <ul>
                          <li>
                            <i className='flaticon-calendar'></i>
                            {moment(event.eventStart).format('DD/MMM/YYYY')}
                          </li>
                          <li>
                            <i className='flaticon-alarm-clock'></i>
                            {moment(event.eventStart).format('hh:mm a')}
                          </li>
                          <li>
                            <i className='flaticon-alarm-clock'></i>
                            {moment(event.eventEnd).format('hh:mm a')}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-8 col-md-7'>
                    <div className='event-info-item mt-20 item-2'>
                      <h3 className='title'>Organizer</h3>
                      <p>{event.organizer}</p>
                      <div className='info'>
                        <ul>
                          <li>
                            <i className='flaticon-phone-call'></i>{' '}
                            {event.branch.contact.phone}
                          </li>
                          <li>
                            <i className='flaticon-message'></i>{' '}
                            {event.branch.contact.email}
                          </li>
                          <li>
                            <i className='flaticon-placeholder'></i>{' '}
                            {event.branch.address.location}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
        </>
      )}
    </React.Fragment>
  );
}

export default Events;

import React from 'react';
import { useSelector } from 'react-redux';
import { Widget } from '@typeform/embed-react';

const Careers = ({ inline = true }) => {
  const { careerForm } = useSelector(({ settings }) => settings);
  return (
    <Widget id={careerForm} className='career-form' inlineOnMobile={inline} />
  );
};

export default Careers;

import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Heading from '../Heading';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
    slidesToSlide: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export default ({ data, pattern = false }) => {
  return (
    <>
      <Heading title='What they are saying' />
      <section className='testimonial-area'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg'>
              {data && data.length > 0 && (
                <Carousel
                  responsive={responsive}
                  showDots={true}
                  autoPlay={true}
                  arrows={false}
                  infinite={true}
                  itemClass={'px-2'}
                >
                  {data.map((d, i) => (
                    <div className='testimonial-item text-center' key={i}>
                      <p>{d.content}</p>
                      <h4 className='title'>{d.name}</h4>
                      <span>{d.occupation}</span>
                      {d.picture && <img src={d.picture} alt='testimonial' />}
                      <div className='icon'>
                        <img src='/assets/images/quote-icon.png' alt='' />
                      </div>
                    </div>
                  ))}
                </Carousel>
              )}
            </div>
          </div>
        </div>
        {pattern && (
          <div className='testimonial-pattern'>
            <img src='/assets/images/testimonial-pattern.png' alt='' />
          </div>
        )}
      </section>
    </>
  );
};

import React from 'react';

export default function () {
  return (
    <section className="contact-info-area">
      <div className="container">
        <div className="contact-info">
          <div className="row">
            <div className="col-lg-4">
              <div className="contact-info-content">
                <h3 className="title">Feel free to get in touch with PopCorn Studio</h3>
                <ul>
                  <li><img src="/assets/images/icon/icon-1.png" alt="" /> 10 Wahdat Road</li>
                  <li><img src="/assets/images/icon/icon-2.png" alt="" /> info@popcornstudio.co</li>
                  <li><img src="/assets/images/icon/icon-3.png" alt="" /> 03177672676</li>
                  <li><img src="/assets/images/icon/icon-4.png" alt="" /> 24 hours / 7 days a week</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="contact-info-thumb">
                <img src="/logo.jpg" alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="contact-info-text">
                <p>Popcorn Studio is a 24/7 enigmatic co-working space where you can book flexible working hours and get diverse packages to suit all your office space needs. On top of that we also offer lush private single person and team offices!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
import React from 'react';

export default function ({ title, description, features = [], counter }) {
  return (
    <section className="best-creative-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="best-creative-bg">
              <div className="row">
                <div className="col-lg-7">
                  <div className="best-creative-content">
                    <h3 className="title">{title}</h3>
                    <p>{description}</p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="best-creative-list">
                    {counter && <div className="best-creative-seats">
                      <i className={counter.icon}></i>
                      <h3 className="title counter">{counter.text}</h3>
                      <p>{counter.description}</p>
                      {counter.caption && <span>{counter.caption}</span>}
                    </div>}
                    {features && features.length > 0 && (
                      <div className="best-creative-list-item">
                        <ul>
                          {features.map((feature, index) => (
                            <li key={index}><span>{feature}</span> <i className="fa fa-check"></i></li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
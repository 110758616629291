import React from 'react';
import { useSelector } from 'react-redux';
import PageHeader from '../../components/PageHeader';
import Members from '../../components/Members';

import Loader from '../../components/Loader';

function Team() {
  const { setting, loading } = useSelector(({ settings }) => settings);
  return (
    <React.Fragment>
      {loading && <Loader />}
      <PageHeader
        title='Our Team'
        links={[{ title: 'Home' }, { title: 'Team' }]}
      />
      {setting?.management?.length > 0 && (
        <Members data={setting?.management} />
      )}
    </React.Fragment>
  );
}

export default Team;

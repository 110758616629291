import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import {
  DialogContent,
  CircularProgress,
  Grid,
  DialogTitle,
  DialogActions,
  TextField,
  Dialog,
  Button,
} from '@material-ui/core';
import AlertBox from '../../components/AlertBox';

export default function ResetPassword() {
  const history = useHistory();
  const [error, setError] = useState(null);
  const [passwordDialog, setPasswordDialog] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [savingPassword, setSavingPassword] = useState(false);
  const { id, code } = useParams();

  useEffect(() => {
    if (!code || !id) return;
    axios
      .post('/verify-code', { id, code })
      .then((res) => {
        if (res.data.status === 200) {
          setPasswordDialog(true);
          return;
        }
        if (res.data.status === 404) {
          setError(res.data.message);
        }
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'Something went wrong');
      });
  }, [id, code]);

  function savePassword() {
    if (password !== confirmPassword) {
      setConfirmPasswordError(true);
      return;
    }
    setSavingPassword(true);
    setError('');
    axios
      .post('/reset-password', { id, code, password })
      .then((res) => {
        if (res.data.token) {
          localStorage.clear();
          localStorage.setItem('authToken', res.data.token);
          history.push('/');
        }
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'Sometning went wrong');
        setTimeout(() => setError(''), 8000);
        setSavingPassword(false);
      });
  }

  if (passwordDialog) {
    return (
      <>
        <div
          style={{
            minHeight: '100vh',
            backgroundImage: `url("/bg.png")`,
            backgroundRepeat: 'repeat',
            backgroundSize: 'contain',
          }}
        ></div>
        <Dialog open maxWidth='xs' fullWidth onClose={() => {}}>
          <DialogTitle>Set password</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid container item>
                <TextField
                  type='password'
                  variant='outlined'
                  fullWidth
                  label='Password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>
              <Grid container item>
                <TextField
                  type='password'
                  variant='outlined'
                  error={confirmPasswordError}
                  helperText={
                    confirmPasswordError ? 'Passwords do not match' : ''
                  }
                  fullWidth
                  label='Confirm password'
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    setConfirmPasswordError(false);
                  }}
                />
              </Grid>
            </Grid>
            {error && <AlertBox type='error' message={error} />}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={savePassword}
              disabled={savingPassword}
              variant='contained'
              color='primary'
            >
              {savingPassword ? (
                <CircularProgress size={24} color='inherit' />
              ) : (
                'Save'
              )}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  if (error) {
    return (
      <Dialog open maxWidth='xs' fullWidth onClose={() => {}}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <AlertBox type='danger' message={error} />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Grid
      container
      alignItems='center'
      justify='center'
      style={{
        height: '100vh',
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundImage: `url("/bg.png")`,
        backgroundRepeat: 'repeat',
        backgroundSize: 'contain',
        backgroundOpacity: 0.5,
      }}
    >
      <CircularProgress color='primary' thickness={6} size={140} />
    </Grid>
  );
}

import React from 'react';

export default function ({ data }) {
  return (
    <section className='company-logos-area'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12 mb-5'>
            <div className='company-logos-text'>
              <h3 className='title'>Clients we have worked with.</h3>
            </div>
          </div>
          <div className='col-lg-12 my-4'>
            <div className='row'>
              {data?.map((d, i) => (
                <div className='col-sm-4' key={i}>
                  <div className='logo-item mt-15'>
                    <img src={d.image} alt={d.name} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

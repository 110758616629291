import axios from '../apiCaller';
import ReactPixel from 'react-facebook-pixel';
import {
  GETSETTING_ATTEMPT,
  GETSETTING_SUCCESS,
  GETSETTING_FAIL,
  GETMENU_ATTEMPT,
  GETMENU_SUCCESS,
  GETMENU_FAIL,
  GETSLIDES_ATTEMPT,
  GETSLIDES_SUCCESS,
  GETSLIDES_FAIL,
  GETBRANCHES_ATTEMPT,
  GETBRANCHES_SUCCESS,
  GETBRANCHES_FAIL,
  GETBRANCH_ATTEMPT,
  GETBRANCH_SUCCESS,
  GETBRANCH_FAIL,
  CLEARBRANCH,
  SIGNIN_ATTEMPT,
  SIGNIN_SUCCESS,
  SIGNIN_FAIL,
  FETCHUSER_ATTEMPT,
  FETCHUSER_SUCCESS,
  FETCHUSER_FAIL,
  GETEVENTS_ATTEMPT,
  GETEVENTS_SUCCESS,
  GETEVENTS_FAIL,
  GETEVENT_ATTEMPT,
  GETEVENT_SUCCESS,
  GETEVENT_FAIL,
  GETGENERALOFFERINGS_ATTEMPT,
  GETGENERALOFFERINGS_SUCCESS,
  GETGENERALOFFERINGS_FAIL,
  GETBRANCHOFFERINGS_ATTEMPT,
  GETBRANCHOFFERINGS_SUCCESS,
  GETBRANCHOFFERINGS_FAIL,
  SENDINQUIRY_ATTEMPT,
  SENDINQUIRY_SUCCESS,
  SENDINQUIRY_FAIL,
  GETFEATURES_ATTEMPT,
  GETFEATURES_SUCCESS,
  GETFEATURES_FAIL,
  GETTESTIMONIALS_ATTEMPT,
  GETTESTIMONIALS_SUCCESS,
  GETTESTIMONIALS_FAIL,
  GETMEETINGSLOTS_ATTEMPT,
  GETMEETINGSLOTS_SUCCESS,
  GETMEETINGSLOTS_FAIL,
  GETEVENTSLOTS_ATTEMPT,
  GETEVENTSLOTS_SUCCESS,
  GETEVENTSLOTS_FAIL,
  BOOKMEETING_ATTEMPT,
  BOOKMEETING_SUCCESS,
  BOOKMEETING_FAIL,
  RESETBOOKING,
  BOOKEVENT_ATTEMPT,
  BOOKEVENT_SUCCESS,
  BOOKEVENT_FAIL,
  GETCITIES_ATTEMPT,
  GETCITIES_SUCCESS,
  GETCITIES_FAIL,
  GETBLOG_ATTEMPT,
  GETBLOG_SUCCESS,
  GETBLOG_FAIL,
  GETPOST_ATTEMPT,
  GETPOST_SUCCESS,
  GETPOST_FAIL,
  GETPOSTCATEGORIES_ATTEMPT,
  GETPOSTCATEGORIES_SUCCESS,
  GETPOSTCATEGORIES_FAIL,
} from '../constants';
import { PIXEL_ID } from '../../constants';

const attemptAction = (actionType) => ({
  type: actionType,
});

const successAction = (actionType, data) => ({
  type: actionType,
  payload: data,
});

const errorAction = (actionType, err) => ({
  type: actionType,
  payload: err,
});

export const checkStatus = () => {
  if (localStorage) {
    axios.interceptors.request.use(
      async (config) => {
        const token = await localStorage.getItem('authToken');
        if (token !== undefined || token !== null) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    const thunk = async function thunk(dispatch) {
      const token = await localStorage.getItem('authToken');
      try {
        if (token !== undefined || token !== null) {
          dispatch(attemptAction(FETCHUSER_ATTEMPT));
          const body = await axios.get('auth/user');
          dispatch(successAction(FETCHUSER_SUCCESS, body.data));
        }
      } catch (err) {
        dispatch(errorAction(FETCHUSER_FAIL, err));
      }
    };
    return thunk;
  }
  return;
};

export const login = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(SIGNIN_ATTEMPT));
      const body = await axios.post('authenticate', data);
      if (body.data && body.data.token) {
        localStorage.setItem('token', body.data.token);
      }
      dispatch(successAction(SIGNIN_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(SIGNIN_FAIL, err));
    }
  };
  return thunk;
};

export const register = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(SIGNIN_ATTEMPT));
      const body = await axios.post('register', data);
      if (body.data && body.data.token) {
        localStorage.setItem('token', body.data.token);
      }
      dispatch(successAction(SIGNIN_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(SIGNIN_FAIL, err));
    }
  };
  return thunk;
};

export const fetchUser = () => {
  axios.interceptors.request.use(
    async (config) => {
      const token = await localStorage.getItem('token');
      if (token !== undefined) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(FETCHUSER_ATTEMPT));
      const body = await axios.get('user/profile');
      dispatch(successAction(FETCHUSER_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(FETCHUSER_FAIL, err));
    }
  };
  return thunk;
};

export const getSettings = () => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETSETTING_ATTEMPT));
      const body = await axios.get('settings');
      dispatch(successAction(GETSETTING_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GETSETTING_FAIL, err));
    }
  };
  return thunk;
};

export const getMenus = () => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETMENU_ATTEMPT));
      const body = await axios.get('settings/menus');
      dispatch(successAction(GETMENU_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GETMENU_FAIL, err));
    }
  };
  return thunk;
};

export const getSlides = () => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETSLIDES_ATTEMPT));
      const body = await axios.get('settings/slides');
      dispatch(successAction(GETSLIDES_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GETSLIDES_FAIL, err));
    }
  };
  return thunk;
};

export const getBranches = () => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETBRANCHES_ATTEMPT));
      const body = await axios.get('webBranches');
      dispatch(successAction(GETBRANCHES_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GETBRANCHES_FAIL, err));
    }
  };
  return thunk;
};

export const getBranch = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETBRANCH_ATTEMPT));
      const body = await axios.get('branches/' + id);
      dispatch(successAction(GETBRANCH_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GETBRANCH_FAIL, err));
    }
  };
  return thunk;
};

export const clearBranch = () => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(CLEARBRANCH, {}));
  };
  return thunk;
};

export const getEvents = () => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETEVENTS_ATTEMPT));
      const body = await axios.get('events');
      dispatch(successAction(GETEVENTS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GETEVENTS_FAIL, err));
    }
  };
  return thunk;
};

export const getEvent = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETEVENT_ATTEMPT));
      const body = await axios.get(`events/event/${id}`);
      dispatch(successAction(GETEVENT_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GETEVENT_FAIL, err));
    }
  };
  return thunk;
};

export const getGeneralOfferings = () => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETGENERALOFFERINGS_ATTEMPT));
      const body = await axios.get('settings/offerings');
      dispatch(successAction(GETGENERALOFFERINGS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GETGENERALOFFERINGS_FAIL, err));
    }
  };
  return thunk;
};

export const getBranchOfferings = (id) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETBRANCHOFFERINGS_ATTEMPT));
      const body = await axios.get('settings/offerings/' + id);
      dispatch(successAction(GETBRANCHOFFERINGS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GETBRANCHOFFERINGS_FAIL, err));
    }
  };
  return thunk;
};

export const getCities = () => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETCITIES_ATTEMPT));
      const body = await axios.get('settings/cities');
      dispatch(successAction(GETCITIES_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GETCITIES_FAIL, err));
    }
  };
  return thunk;
};

export const getFeatures = () => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETFEATURES_ATTEMPT));
      const body = await axios.get('settings/features');
      dispatch(successAction(GETFEATURES_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GETFEATURES_FAIL, err));
    }
  };
  return thunk;
};

export const getTestimonials = () => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETTESTIMONIALS_ATTEMPT));
      const body = await axios.get('settings/testimonials');
      dispatch(successAction(GETTESTIMONIALS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GETTESTIMONIALS_FAIL, err));
    }
  };
  return thunk;
};

export const getPosts = (params = {}) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETBLOG_ATTEMPT));
      const body = await axios.get('settings/posts', { params: params });
      dispatch(successAction(GETBLOG_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GETBLOG_FAIL, err));
    }
  };
  return thunk;
};

export const getPostCategoriess = () => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETPOSTCATEGORIES_ATTEMPT));
      const body = await axios.get('settings/postCategories');
      dispatch(successAction(GETPOSTCATEGORIES_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GETPOSTCATEGORIES_FAIL, err));
    }
  };
  return thunk;
};

export const getPost = (slug) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETPOST_ATTEMPT));
      const body = await axios.get(`settings/posts/${slug}`);
      dispatch(successAction(GETPOST_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GETPOST_FAIL, err));
    }
  };
  return thunk;
};

export const sendInquiry = (data, type) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(SENDINQUIRY_ATTEMPT));
      const body = await axios.post(`inquiry/${type}`, data);
      if (PIXEL_ID) {
        if (type === 'contact') {
          ReactPixel.track('Contact', data);
        }
        if (type === 'office') {
          ReactPixel.track('Schedule', {
            appointment_type: 'bookTour',
            city: 'Lahore',
            ...data,
          });
        }
      }
      dispatch(successAction(SENDINQUIRY_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(SENDINQUIRY_FAIL, err));
    }
  };
  return thunk;
};

export const fetchMeetingSlots = (id, rid, data, token) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETMEETINGSLOTS_ATTEMPT));
      const body = await axios.post(
        `bookings/availableSlots/${id}/${rid}`,
        data,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      dispatch(successAction(GETMEETINGSLOTS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GETMEETINGSLOTS_FAIL, err));
    }
  };
  return thunk;
};

export const bookMeeting = (data, token) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(BOOKMEETING_ATTEMPT));
      const body = await axios.post(`bookings/webMeetingRoom`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch(successAction(BOOKMEETING_SUCCESS, body.data));
      if (PIXEL_ID) {
        ReactPixel.track('Lead', data);
      }
    } catch (err) {
      dispatch(errorAction(BOOKMEETING_FAIL, err));
    }
  };
  return thunk;
};

export const fetchEventSlots = (id, rid, data, token) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETEVENTSLOTS_ATTEMPT));
      const body = await axios.post(
        `bookings/availableEventSlots/${id}/${rid}`,
        data,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      dispatch(successAction(GETEVENTSLOTS_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GETEVENTSLOTS_FAIL, err));
    }
  };
  return thunk;
};

export const resetBooking = () => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(RESETBOOKING, {}));
  };
  return thunk;
};

export const bookEvent = (data, token) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(BOOKEVENT_ATTEMPT));
      const body = await axios.post(`bookings/eventHall`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch(successAction(BOOKEVENT_SUCCESS, body.data));
      if (PIXEL_ID) {
        ReactPixel.track('Lead', data);
      }
    } catch (err) {
      dispatch(errorAction(BOOKEVENT_FAIL, err));
    }
  };
  return thunk;
};

import React from 'react';
import PageHeader from '../../components/PageHeader';
import ContactInfo from '../../components/ContactInfo';
import Form from '../../components/Form';

function Contact() {
  return (
    <React.Fragment>
      <PageHeader title='Contact' links={[{ title: 'Home' }, { title: 'Contact' }]} />
      <ContactInfo />
      <Form />
    </React.Fragment>
  );
}

export default Contact;

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { bookMeeting, fetchMeetingSlots, resetBooking } from '../../redux/actions';
import moment from 'moment';
import utils from '../../utils';
import PageHeader from '../../components/PageHeader';
import QuickHelp from '../../components/QuickHelp';
import BranchSelector from '../../components/BranchSelector';
import DatePicker from '../../components/DatePicker';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import Auth from '../../components/AuthForm';

const initialBooking = {
  startDate: new Date(),
  room: '',
  slots: []
};

function BookMeeting() {
  const dispatch = useDispatch();

  const { branch, slots, slotLoading, bookingLoading, bookingStatus, bookingMessage } = useSelector(({ settings }) => settings);
  const { token } = useSelector(({ auth }) => auth);
  const [slotError, setSlotError] = useState();

  const [booking, setBooking] = useState(initialBooking);

  const bookNow = () => {
    const data = {
      ...booking,
      branch: branch._id,
      hours: booking.slots.length * 0.5,
      startDate: moment(booking.startDate).format('YYYY-MM-DD'),
    };
    dispatch(bookMeeting(data, token));
  };


  return (
    <React.Fragment>
      <PageHeader title='Book Meeting Room' links={[{ title: 'Home' }, { title: 'Book Meeting Room' }]} />
      <section className="write-massage-area pb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="write-massage-content">
                <div className="write-massage-title">
                  <h3 className="title">Book Meeting Room</h3>
                </div>

                <div className="container">
                  {!token ?
                    <Auth /> :
                    <>
                      {bookingLoading && <CircularProgress />}
                      {bookingMessage ?
                        <div className="row">
                          {bookingMessage &&
                            <div className="col-lg-12 my-5">
                              <small className={bookingStatus === 200 ? "text-success font-weight-bold" : "text-danger font-weight-bold"}>{bookingMessage}</small>
                            </div>
                          }
                          <div className="col-lg-12">
                            <Button variant='outlined' color='primary' onClick={() => {
                              setBooking(initialBooking);
                              dispatch(resetBooking());
                            }}>Continue</Button>
                          </div>
                        </div>
                        :
                        <div className="row">

                          <div className="col-lg-12 my-2">
                            <BranchSelector variant='outlined' size="small" />
                          </div>

                          {branch && branch.rooms &&
                            <div className="col-lg-12 my-2">
                              <FormControl component="fieldset">
                                <FormLabel component="legend">Room</FormLabel>
                                <RadioGroup aria-label="room" name="room" value={booking.room} onChange={(e) => {
                                  setBooking({ ...booking, room: e.target.value, startDate: moment() });
                                  dispatch(fetchMeetingSlots(branch._id, e.target.value, { start: moment().format('YYYY-MM-DD') }, token));
                                }}>
                                  {branch.rooms.length > 0 && branch.rooms.filter(x => !x.category.eventRoom).map((room) => (
                                    <FormControlLabel key={room._id} value={room._id} control={<Radio />} label={room.title} />
                                  ))}
                                </RadioGroup>
                              </FormControl>
                            </div>
                          }

                          {branch && booking.room &&
                            <div className="col-lg-12 my-2">
                              <DatePicker
                                value={booking.startDate}
                                setDate={(d) => {
                                  setBooking({ ...booking, startDate: d });
                                  dispatch(fetchMeetingSlots(branch._id, booking.room, { start: moment(d).format('YYYY-MM-DD') }, token));
                                }}
                              />
                            </div>
                          }

                          <div className="col-lg-10 my-2">
                            {slotLoading && <CircularProgress />}
                          </div>

                          {branch && booking.startDate && slots &&
                            <div className="col-lg-10 my-2">
                              <p>Pick Slots</p>
                              {Boolean(slotError) &&
                                <p><small className="text-danger font-weight-bold">{slotError}</small></p>
                              }
                              <small className="text-info">Each slot has half hour duration</small>
                              <div className="form-check form-check-inline d-flex flex-wrap">
                                {slots.length > 0 && slots.map((slot, index) => (
                                  <button
                                    className={booking.slots.includes(slot) ? `btn btn-primary btn-sm m-2` : `btn btn-outline-primary btn-sm m-2`}
                                    disabled={!booking.slots.includes(slot) && booking.slots.length === 4}
                                    key={index}
                                    onClick={() => {
                                      if (booking.slots.includes(slot)) {
                                        setBooking({ ...booking, slots: booking.slots.filter(x => x !== slot) });
                                      } else {
                                        if (booking.slots.length >= 4) {
                                          setSlotError('Maximum of 2 hours allowed');
                                        } else {
                                          setBooking({ ...booking, slots: [...booking.slots, slot] });
                                          setSlotError(null);
                                        }
                                      }
                                    }}>
                                    {utils.slotHelper(slot)}
                                  </button>
                                ))}
                              </div>

                            </div>

                          }
                          {booking && booking.slots.length > 0 && booking.room && branch &&
                            <div className="col-lg-12">
                              <div className="input-box mt-10">
                                <Button
                                  variant='contained'
                                  color='primary'
                                  disabled={bookingLoading}
                                  onClick={bookNow}
                                  startIcon={<CalendarTodayIcon />}
                                >Book Now
                              </Button>
                              </div>
                            </div>
                          }
                        </div>
                      }
                    </>
                  }
                </div>
              </div>
            </div>
            <QuickHelp />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default BookMeeting;

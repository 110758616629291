import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import {
  getSlides,
  getEvents,
  getGeneralOfferings,
  getTestimonials,
  getFeatures,
} from '../../redux/actions';

import BookTourCTA from '../../components/BookTourCTA';
import Features from '../../components/Features';
import Services from '../../components/Services';
import Pricing from '../../components/Pricing';
import Testimonials from '../../components/Testimonials';
import Form from '../../components/Form';
import Slider from '../../components/Slider';
import News from '../../components/News';
import Plans from '../../components/Plans';
import GalleryBox from '../../components/GalleryBox';
import Loader from '../../components/Loader';
import Infographics from '../../components/Infographics';
import VideoPopUp from '../../components/VideoPopUp';

function Home() {
  const [videoDialog, setVideoDialog] = useState(false);
  const dispatch = useDispatch();
  const { slides, testimonials, loading, offerings, ammenities } = useSelector(
    ({ home }) => home
  );
  const { events, setting } = useSelector(({ settings }) => settings);

  useEffect(() => {
    dispatch(getSlides());
    dispatch(getEvents());
    dispatch(getGeneralOfferings());
    dispatch(getFeatures());
    dispatch(getTestimonials());
  }, [dispatch]);

  useEffect(() => {
    if (!setting) return;
    if (setting?.youtubeUrl) {
      const timeout = setTimeout(() => {
        setVideoDialog(true);
      }, 5000);
      return () => {
        clearTimeout(timeout);
        setVideoDialog(false);
      };
    }
  }, [setting]);

  return (
    <React.Fragment>
      {loading && <Loader />}
      {slides && slides.length > 0 && <Slider data={slides} />}
      {setting?.enableStats && (
        <Infographics
          data={setting?.statsData}
          title={setting?.statsTitle}
          description={setting?.statsDescription}
        />
      )}
      {ammenities && ammenities.length > 0 && <Features data={ammenities} />}
      <Services />
      <Pricing />
      {offerings && <Plans data={offerings} />}
      {setting && (
        <BookTourCTA
          title={setting.bookTourTitle}
          link={setting.bookTourLink}
          linkLabel={setting.bookTourLinkLabel}
          url={setting.bookTourPic}
        />
      )}
      {testimonials && testimonials.length > 0 && (
        <Testimonials data={testimonials} />
      )}
      <GalleryBox />
      <Form />
      {events && <News data={events} />}
      <VideoPopUp
        open={videoDialog}
        handleClose={() => setVideoDialog(false)}
        url={setting?.youtubeUrl}
      />
    </React.Fragment>
  );
}

export default Home;

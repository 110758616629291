import React from 'react';

export default ({ title }) => {
  return (
    <div className="testimonial-title-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="testimonial-title text-center">
              <h2 className="title">{title}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

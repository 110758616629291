import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { login, register } from '../../redux/actions';

import BranchSelector from '../BranchSelector';
import Button from '@material-ui/core/Button';
import LockIcon from '@material-ui/icons/Lock';
import AlertBox from '../AlertBox';

const LoginForm = ({ setForm }) => {
  const [loginInfo, setLoginInfo] = useState({ email: '', password: '' });
  const history = useHistory();
  const dispatch = useDispatch();

  const authenticate = (e) => {
    e.preventDefault();
    dispatch(login(loginInfo));
  };

  return (
    <>
      <div className='form-group'>
        <label htmlFor='email'>Email</label>
        <input
          required
          type='email'
          name='email'
          id='email'
          className='form-control'
          placeholder='Email'
          aria-describedby='emailId'
          onChange={(e) =>
            setLoginInfo({ ...loginInfo, email: e.target.value })
          }
        />
      </div>
      <div className='form-group'>
        <label htmlFor='password'>Password</label>
        <input
          required
          type='password'
          className='form-control'
          name='password'
          id='password'
          placeholder='Password'
          onChange={(e) =>
            setLoginInfo({ ...loginInfo, password: e.target.value })
          }
        />
      </div>
      <div className='col-lg-12 m-0 p-0'>
        <div className='row'>
          <div className='col-lg-3 col-sm-6'>
            <Button
              variant='contained'
              color='primary'
              disabled={!loginInfo || !loginInfo.email || !loginInfo.password}
              onClick={authenticate}
              startIcon={<LockIcon />}
            >
              Login In
            </Button>
          </div>
          <div className='col-lg-6 col-sm-6'>
            <Button
              variant='text'
              color='primary'
              style={{ textTransform: 'uppercase' }}
              onClick={() => setForm('register')}
            >
              Register
            </Button>
          </div>
        </div>
        <div className='row mt-5'>
          <div className='col-lg-6 col-sm-6'>
            <Button
              variant='text'
              color='primary'
              type='button'
              onClick={() => history.push('/forgot-password')}
              style={{
                textTransform: 'uppercase',
                textDecoration: 'underline',
              }}
            >
              Forgot Password ?
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

const RegisterForm = ({ setForm }) => {
  const [registerInfo, setRegisterInfo] = useState({
    name: '',
    email: '',
    password: '',
    phone: '',
    company: '',
  });
  const dispatch = useDispatch();
  const { branch } = useSelector(({ settings }) => settings);

  const registerUser = (e) => {
    e.preventDefault();
    dispatch(register({ ...registerInfo, branch: branch._id }));
  };
  return (
    <>
      <div className='form-group'>
        <BranchSelector variant='outlined' />
      </div>
      {branch && (
        <>
          <div className='form-group'>
            <label htmlFor='name'>Name</label>
            <input
              required
              type='text'
              name='name'
              id='name'
              className='form-control'
              placeholder='Name'
              aria-describedby='nameId'
              onChange={(e) =>
                setRegisterInfo({ ...registerInfo, name: e.target.value })
              }
            />
          </div>
          <div className='form-group'>
            <label htmlFor='name'>Company</label>
            <input
              required
              type='text'
              name='company'
              id='company'
              className='form-control'
              placeholder='Company'
              aria-describedby='companyId'
              onChange={(e) =>
                setRegisterInfo({
                  ...registerInfo,
                  company: e.target.value,
                })
              }
            />
          </div>
          <div className='form-group'>
            <label htmlFor='name'>Phone</label>
            <input
              required
              type='tel'
              name='phone'
              id='phone'
              className='form-control'
              placeholder='Phone'
              aria-describedby='phoneId'
              onChange={(e) =>
                setRegisterInfo({ ...registerInfo, phone: e.target.value })
              }
            />
          </div>
          <div className='form-group'>
            <label htmlFor='email'>Email</label>
            <input
              required
              type='email'
              name='email'
              id='email'
              className='form-control'
              placeholder='Email'
              aria-describedby='emailId'
              onChange={(e) =>
                setRegisterInfo({ ...registerInfo, email: e.target.value })
              }
            />
          </div>
          <div className='form-group'>
            <label htmlFor='password'>Password</label>
            <input
              required
              type='password'
              className='form-control'
              name='password'
              id='password'
              placeholder='Password'
              onChange={(e) =>
                setRegisterInfo({
                  ...registerInfo,
                  password: e.target.value,
                })
              }
            />
          </div>
        </>
      )}
      <div className='col-lg-12 my-5'>
        <div className='row'>
          <div className='col-lg-3 col-sm-6'>
            <Button
              variant='contained'
              color='primary'
              disabled={
                !registerInfo ||
                !registerInfo.email ||
                !registerInfo.password ||
                !registerInfo.phone ||
                !registerInfo.company ||
                !branch
              }
              onClick={registerUser}
              startIcon={<LockIcon />}
            >
              Register
            </Button>
          </div>
          <div className='col-lg-3 col-sm-6'>
            <Button
              variant='text'
              color='primary'
              onClick={() => setForm('login')}
              style={{ textTransform: 'uppercase' }}
            >
              Login
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

function Auth() {
  const [form, setForm] = useState('login');
  const { message, error } = useSelector(({ auth }) => auth);

  const getForm = () => {
    switch (form) {
      case 'login':
        return <LoginForm setForm={setForm} />;
      case 'register':
        return <RegisterForm setForm={setForm} />;
      default:
        return null;
    }
  };

  return (
    <div className='container'>
      {message && <AlertBox type='sucess' message={message} />}
      {error && <AlertBox type='danger' message={error} />}
      {getForm()}
    </div>
  );
}

export default Auth;

import React from 'react';
import { useSelector } from 'react-redux';

function QuickHelp() {
  const { setting } = useSelector(({ settings }) => settings);
  return (
    <div className='col-lg-4'>
      <div className='book-a-tour-info'>
        <span>Need quick help?</span>
        <p>
          Speak with a human to filling out a form? call corporate office and we
          will connect you with a team member who can help.
        </p>
        {setting && (
          <h3
            className='title cursor-pointer'
            onClick={() => window.open(`tel:${setting?.phone}`, '_self')}
          >
            {setting.phone}
          </h3>
        )}
      </div>
    </div>
  );
}

export default QuickHelp;

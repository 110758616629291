const isDev = process.env.NODE_ENV === 'developmment';

const logger = (store) => (next) => (action) => {
  if (isDev) {
    if (action && action.type) {
      console.group(action.type);
      console.info('dispatching', action);
      console.groupEnd();
      console.info('next state', store.getState());
    }
  }
  let result = next(action);
  return result;
};

export default logger;

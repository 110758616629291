import 'date-fns';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

export default function MaterialUIPickers({
  value = moment(),
  setDate,
  minDate = moment(),
  label = 'Pick Date',
}) {
  const handleDateChange = (date) => {
    setDate(date);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify='flex-start'>
        <KeyboardDatePicker
          disableToolbar
          minDate={minDate}
          margin='normal'
          format='yyyy-MM-dd'
          id='date-picker-inline'
          label={label}
          value={value}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
}

/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as moment from 'moment';

import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { TextField, Grid, useMediaQuery } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';

import BranchSelector from '../../components/BranchSelector';

import { getBranchOfferings, sendInquiry } from '../../redux/actions';

const useStyles = makeStyles((theme) => ({
  actions: {
    padding: theme.spacing(1, 3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 5),
    },
  },
  button: {
    textTransform: 'none',
  },
}));

function BookTourDialog({ open, onClose, initial }) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const initialInquiry = {
    message: '',
    webData: {
      name: '',
      email: '',
      phone: '',
      date: moment().format('yyyy-MM-DD'),
      time: moment().format('hh:mm'),
      companySize: '',
    },
  };
  const dispatch = useDispatch();
  const { branch, offerings } = useSelector(({ settings }) => settings);
  const {
    response,
    loading,
    offerings: generalOfferings,
  } = useSelector(({ home }) => home);
  const [errors, setErrors] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [inquiry, setInquiry] = useState(initialInquiry);
  const [offering, setOffering] = useState(null);
  const radioGroupRef = useRef(null);

  useEffect(() => {
    if (!branch) return;
    if (branch !== null) {
      dispatch(getBranchOfferings(branch._id));
    }
  }, [branch, dispatch]);

  useEffect(() => {
    if (!initial) return;
    setOffering(initial);
  }, [initial]);

  function validateEmail(email) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  }

  const send = () => {
    let message = inquiry?.message;
    const webData = inquiry?.webData;
    const res =
      offerings?.find((x) => x._id === offering) ||
      generalOfferings?.find((x) => x._id === offering) ||
      branch?.packages?.find((x) => x._id === offering);
    if (message === '') {
      message = res?.title;
    }
    if (
      !webData.name ||
      !webData.email ||
      !webData.phone ||
      !webData.date ||
      !webData.time ||
      !webData.companySize ||
      !branch
    ) {
      setErrors(true);
    } else {
      setErrors(false);
      setEmailError(false);
      dispatch(
        sendInquiry({ ...inquiry, message, branch: branch._id }, 'office')
      );
      setInquiry(initialInquiry);
    }
  };

  const handleRadioChange = (event) => {
    setOffering(event.target.value);
    const res =
      offerings?.find((x) => x._id === event.target.value) ||
      generalOfferings?.find((x) => x._id === event.target.value) ||
      branch?.packages?.find((x) => x._id === event.target.value);
    setInquiry({
      ...inquiry,
      message:
        event.target.value === 'Others' ? event.target.value : res?.title,
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='sm'
      fullScreen={isMobile}
      scroll='body'
    >
      <DialogTitle align='center'>Book Your Tour</DialogTitle>
      <DialogContent>
        <Grid container direction='column'>
          {response && (
            <Grid item sm={12}>
              <small className={'text-success font-weight-bold'}>
                {response}
              </small>
            </Grid>
          )}
          {errors && (
            <Grid item sm={12} className='my-2'>
              <small className={'text-danger font-weight-bold'}>
                Please fill out all the fields.
              </small>
            </Grid>
          )}
          {emailError && (
            <Grid item sm={12}>
              <small className={'text-danger font-weight-bold'}>
                Email is invalid
              </small>
            </Grid>
          )}
          <Grid item sm={12} className='my-2'>
            <p>Branch</p>
            <BranchSelector variant='outlined' />
          </Grid>
          <Grid item sm={12} className='my-2'>
            <p>Inquire About</p>
            <RadioGroup
              row
              ref={radioGroupRef}
              aria-label='offering'
              name='offering'
              value={offering}
              onChange={handleRadioChange}
            >
              {branch?.packages?.map((option) => (
                <FormControlLabel
                  value={option._id}
                  key={option._id}
                  control={<Radio />}
                  label={option.title}
                />
              ))}
              {offerings?.map((option) => (
                <FormControlLabel
                  value={option._id}
                  key={option._id}
                  control={<Radio />}
                  label={option.title}
                />
              ))}
              {generalOfferings?.map((option) => (
                <FormControlLabel
                  value={option._id}
                  key={option._id}
                  control={<Radio />}
                  label={option.title}
                />
              ))}
              <FormControlLabel
                value={'Others'}
                control={<Radio />}
                label={'Others'}
              />
            </RadioGroup>
          </Grid>
          <Grid item container spacing={3}>
            <Grid item sm={6} xs={12}>
              <TextField
                label='Name'
                placeholder='Enter your Full Name'
                value={inquiry?.webData?.name}
                variant='outlined'
                onChange={(e) =>
                  setInquiry({
                    ...inquiry,
                    webData: {
                      ...inquiry.webData,
                      name: e.target.value,
                    },
                  })
                }
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                label='Email'
                type='email'
                placeholder='Enter your email'
                value={inquiry?.webData?.email}
                variant='outlined'
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  validateEmail(e.target.value);
                  setInquiry({
                    ...inquiry,
                    webData: {
                      ...inquiry.webData,
                      email: e.target.value,
                    },
                  });
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                label='Phone'
                placeholder='Enter your Contact Number'
                value={inquiry?.webData?.phone}
                variant='outlined'
                type='tel'
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  setInquiry({
                    ...inquiry,
                    webData: {
                      ...inquiry.webData,
                      phone: e.target.value,
                    },
                  })
                }
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                placeholder='Enter your Company Size'
                label='Company Size'
                type='number'
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={inquiry?.webData?.companySize}
                variant='outlined'
                onChange={(e) => {
                  setInquiry({
                    ...inquiry,
                    webData: {
                      ...inquiry.webData,
                      companySize: e.target.value,
                    },
                  });
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                label='Date'
                value={inquiry?.webData?.date}
                variant='outlined'
                fullWidth
                InputLabelProps={{ shrink: true }}
                type='date'
                onChange={(e) =>
                  setInquiry({
                    ...inquiry,
                    webData: {
                      ...inquiry.webData,
                      date: e.target.value,
                    },
                  })
                }
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                label='Time'
                type='time'
                value={inquiry?.webData?.time}
                variant='outlined'
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  setInquiry({
                    ...inquiry,
                    webData: {
                      ...inquiry.webData,
                      time: e.target.value,
                    },
                  });
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={onClose} className={classes.button}>
          Cancel
        </Button>
        <button
          className='main-btn main-btn-2'
          onClick={send}
          disabled={loading || errors || emailError}
          css={{ lineHeight: '36px', padding: '0 20px', fontSize: '14px' }}
        >
          Confirm
        </button>
      </DialogActions>
    </Dialog>
  );
}

export default BookTourDialog;

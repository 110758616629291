import {
  SIGNIN_ATTEMPT,
  SIGNIN_SUCCESS,
  SIGNIN_FAIL,
  FETCHUSER_ATTEMPT,
  FETCHUSER_SUCCESS,
  FETCHUSER_FAIL,
} from '../constants';


const initialState = {
  status: null,
  message: null,
  loading: false,
  token: localStorage.getItem('token'),
  error: false,
  user: null
};

export default function homeReducer(state = initialState, action) {
  switch (action.type) {
    case SIGNIN_ATTEMPT:
      return { ...state, loading: true, status: null, message: null };
    case SIGNIN_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case SIGNIN_FAIL:
      return { ...state, loading: false, error: action.payload.response?.data?.message };
    case FETCHUSER_ATTEMPT:
      return { ...state, loading: true };
    case FETCHUSER_SUCCESS:
      return { ...state, loading: false, user: action.payload };
    case FETCHUSER_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}

/** @jsx jsx */
import { jsx } from '@emotion/core';
import BranchSelector from '../BranchSelector';

const ChoosePricingPlan = () => {
  return (
    <div
      className='pricing-title-area'
      css={{ marginTop: 30, marginBottom: 45 }}
    >
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-6'>
            <div className='pricng-title text-center'>
              <h3 className='title'>Choose pricing plans for</h3>
            </div>
          </div>
        </div>
        <div className='row justify-content-center my-3'>
          <div className='col-lg-3 text-center'>
            <BranchSelector variant={'outlined'} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChoosePricingPlan;

import React from 'react';
import PageHeader from '../../components/PageHeader';
import Form from '../../components/Form';
import { Container, Typography } from '@material-ui/core';

const PrivacyPolicy = () => {
  return (
    <React.Fragment>
      <PageHeader
        title='Privacy Policy'
        links={[{ title: 'Home' }, { title: 'Privacy Policy' }]}
      />
      <Container>
        <Typography variant='h4' style={{ marginTop: '32px' }}>
          Introduction
        </Typography>
        <Typography style={{ marginTop: '16px' }}>
          At <strong>PopCorn Studio</strong>, we are committed to protecting the
          privacy of our customers and website visitors. Our team is dedicated
          to ensuring that your personal information is kept confidential and
          secure at all times.
        </Typography>
        <Typography variant='h4' style={{ marginTop: '32px' }}>
          What information do we collect?
        </Typography>
        <Typography style={{ marginTop: '16px' }}>
          We may collect personal information from you when you visit our
          website, register on our site, place an order, subscribe to our
          newsletter, respond to a survey or fill out a form. This information
          may include your name, email address, mailing address, phone number,
          credit card information or other details.
        </Typography>
        <Typography variant='h4' style={{ marginTop: '32px' }}>
          How do we use your information?
        </Typography>
        <Typography style={{ marginTop: '16px' }}>
          We may use the information we collect from you to personalize your
          experience, improve our website, process transactions, send periodic
          emails, and provide customer service. Your information, whether public
          or private, will not be sold, exchanged, transferred, or given to any
          other company for any reason whatsoever, without your consent, other
          than for the express purpose of delivering the purchased product or
          service requested.
        </Typography>
        <Typography variant='h4' style={{ marginTop: '32px' }}>
          How do we protect your information?
        </Typography>
        <Typography style={{ marginTop: '16px' }}>
          We implement a variety of security measures to maintain the safety of
          your personal information when you place an order or enter, submit, or
          access your personal information. We offer the use of a secure server.
          All supplied sensitive/credit information is transmitted via Secure
          Socket Layer (SSL) technology and then encrypted into our Payment
          gateway providers database only to be accessible by those authorized
          with special access rights to such systems, and are required to keep
          the information confidential.
        </Typography>
        <Typography variant='h4' style={{ marginTop: '32px' }}>
          Do we use cookies?
        </Typography>
        <Typography style={{ marginTop: '16px' }}>
          Yes. Cookies are small files that a site or its service provider
          transfers to your computer's hard drive through your Web browser (if
          you allow) that enables the sites or service providers systems to
          recognize your browser and capture and remember certain information.
          We use cookies to help us remember and process the items in your
          shopping cart, understand and save your preferences for future visits
          and compile aggregate data about site traffic and site interaction so
          that we can offer better site experiences and tools in the future.
        </Typography>
        <Typography variant='h4' style={{ marginTop: '32px' }}>
          Changes to our Privacy Policy
        </Typography>
        <Typography style={{ marginTop: '16px' }}>
          If we decide to change our privacy policy, we will post those changes
          on this page, and/or update the Privacy Policy modification date
          below. Policy changes will apply only to information collected after
          the date of the change.
        </Typography>
        <Typography variant='h4' style={{ marginTop: '32px' }}>
          Contacting Us
        </Typography>
        <Typography style={{ marginTop: '16px' }}>
          If there are any questions regarding this privacy policy, you may
          contact us using the information below:
        </Typography>
        <Form />
      </Container>
    </React.Fragment>
  );
};
export default PrivacyPolicy;

import React from 'react';
import { Link } from 'react-router-dom';

export default function ({ data }) {
  return (
    <section className="join-community-area">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-6">
            <div className="join-community-content">
              {data.title && <h3 className="title animated wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">{data.title}</h3>}
              {data.description && <p className=" animated wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="300ms">{data.description}</p>}
              <Link className="main-btn animated wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="600ms" to="/branches">Read more</Link>
            </div>
          </div>
          {data.picture &&
            <div className="col-lg-6">
              <img src={data.picture} alt="communityPic" />
            </div>
          }
        </div>
      </div>
      {/* <div className="join-community-counter">
        <div className="item">
          <h3 className="title counter">880</h3>
          <p>Creative people space is available.</p>
        </div>
        <div className="item item-2">
          <h3 className="title"><span className="counter">70</span>+</h3>
          <p>Conference & meeting rooms available.</p>
        </div>
      </div> */}
      <div className="community-logo">
        <img src="/assets/images/community-logo.png" alt="" />
      </div>
    </section>
  );
}

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PageHeader from '../../components/PageHeader';
import GalleryGrid from '../../components/GalleryGrid';
import BranchSelector from '../../components/BranchSelector';
import DatePicker from '../../components/DatePicker';
import { Grid, CircularProgress } from '@material-ui/core';
import * as moment from 'moment';

function Gallery() {
  const { loading, setting, branch } = useSelector(({ settings }) => settings);
  const [date, setDate] = useState();

  const filterGallery = (x) => {
    if (branch) {
      return x.location === branch?._id;
    }
    if (date) {
      return moment(x.timestamp).isBefore(moment(date));
    }
    if (branch && date) {
      return x.location === branch?._id && x.timestamp === date;
    }
    return x;
  };

  const gallery = setting?.galleryImages
    ?.filter(filterGallery)
    ?.map((x) => x.image);

  return (
    <React.Fragment>
      <PageHeader title='Gallery' />
      <Grid
        container
        alignItems='center'
        jusitfy='center'
        direction='row'
        className='container mt-3'
      >
        <Grid item sm={3} className='mt-3'>
          <BranchSelector variant='outlined' />
        </Grid>
        <Grid item sm={3}>
          <DatePicker
            setDate={setDate}
            value={date}
            minDate={new Date('23062000')}
          />
        </Grid>
      </Grid>
      {loading && (
        <Grid
          container
          alignItems='center'
          justifyContent='center'
          direction='row'
          className='container p-5'
        >
          <CircularProgress size={60} />
        </Grid>
      )}
      {!loading && <GalleryGrid data={gallery} />}
    </React.Fragment>
  );
}

export default Gallery;

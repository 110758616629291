import React from 'react';
import { useSelector } from 'react-redux';
import PageHeader from '../../components/PageHeader';
import KnowUs from '../../components/KnowUs';
import CallToAction from '../../components/CallToAction';
import Testimonials from '../../components/Testimonials';
import Community from '../../components/Community';
import Members from '../../components/Members';

import Loader from '../../components/Loader';

function Home() {
  const { testimonials, loading } = useSelector(({ home }) => home);
  const { setting } = useSelector(({ settings }) => settings);
  return (
    <React.Fragment>
      {loading && <Loader />}
      <PageHeader
        title='About'
        links={[{ title: 'Home' }, { title: 'About' }]}
      />
      <KnowUs />
      <CallToAction
        version='v2'
        title={setting?.ctaTitle || 'PopCorn is changing the way people think'}
        link={setting?.ctaLink || '/branches'}
        image={
          setting?.ctaPic ||
          'https://images.unsplash.com/photo-1594125674965-70d796b6693f?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2850&q=80'
        }
      />
      {testimonials && <Testimonials data={testimonials} />}
      {setting && setting.enableCommunitySection && (
        <Community
          data={{
            title: setting?.communityTitle,
            description: setting?.communityDescription,
            picture: setting?.communityPic,
          }}
        />
      )}
      {setting && setting?.management?.length > 0 && (
        <Members data={setting.management} />
      )}
    </React.Fragment>
  );
}

export default Home;

let slots = [
  {
    id: 1,
    hour: 9,
    minute: 0,
    name: "9:00am"
  },
  {
    id: 2,
    hour: 9,
    minute: 30,
    name: "9:30am"
  },
  {
    id: 3,
    hour: 10,
    minute: 0,
    name: "10:00am"
  },
  {
    id: 4,
    hour: 10,
    minute: 30,
    name: "10:30am"
  },
  {
    id: 5,
    hour: 11,
    minute: 0,
    name: "11:00am"
  },
  {
    id: 6,
    hour: 11,
    minute: 30,
    name: "11:30am"
  },
  {
    id: 7,
    hour: 12,
    minute: 0,
    name: "12:00pm"
  },
  {
    id: 8,
    hour: 12,
    minute: 30,
    name: "12:30pm"
  },
  {
    id: 9,
    hour: 13,
    minute: 0,
    name: "1:00pm"
  },
  {
    id: 10,
    hour: 13,
    minute: 30,
    name: "1:30pm"
  },
  {
    id: 11,
    hour: 14,
    minute: 0,
    name: "2:00pm"
  },
  {
    id: 12,
    hour: 14,
    minute: 30,
    name: "2:30pm"
  },
  {
    id: 13,
    hour: 15,
    minute: 0,
    name: "3:00pm"
  },
  {
    id: 14,
    hour: 15,
    minute: 30,
    name: "3:30pm"
  },
  {
    id: 15,
    hour: 16,
    minute: 0,
    name: "4:00pm"
  },
  {
    id: 16,
    hour: 16,
    minute: 30,
    name: "4:30pm"
  },
  {
    id: 17,
    hour: 17,
    minute: 0,
    name: "5:00pm"
  },
  {
    id: 18,
    hour: 17,
    minute: 30,
    name: "5:30pm"
  },
  {
    id: 19,
    hour: 18,
    minute: 0,
    name: "6:00pm"
  },
  {
    id: 20,
    hour: 18,
    minute: 30,
    name: "6:30pm"
  },
  {
    id: 21,
    hour: 19,
    minute: 0,
    name: "7:00pm"
  },
  {
    id: 22,
    hour: 19,
    minute: 30,
    name: "7:30pm"
  },
  {
    id: 23,
    hour: 20,
    minute: 0,
    name: "8:00pm"
  },
  {
    id: 24,
    hour: 20,
    minute: 30,
    name: "8:30pm"
  },
  {
    id: 25,
    hour: 21,
    minute: 0,
    name: "9:00pm"
  },
  {
    id: 26,
    hour: 21,
    minute: 30,
    name: "9:30pm"
  },
  {
    id: 27,
    hour: 22,
    minute: 0,
    name: "10:00pm"
  },
  {
    id: 28,
    hour: 22,
    minute: 30,
    name: "10:30pm"
  },
  {
    id: 29,
    hour: 23,
    minute: 0,
    name: "11:00pm"
  },
  {
    id: 30,
    hour: 23,
    minute: 30,
    name: "11:30pm"
  }
];
const utils = {
  slots: slots,
  slotHelper: function (id) {
    return slots.find(slot => slot.id === id).name;
  }
};
export default utils;

/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useLocation, Link } from 'react-router-dom';

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const breakpoints = [600, 834, 959.95, 1200, 1600];
export const mqMin = breakpoints.map((bp) => `@media (min-width: ${bp}px)`);
export const mqMax = breakpoints.map((bp) => `@media (max-width: ${bp}px)`);

export const PRIMARY_COLOR = '#ff8f13';
export const SECONDARY_COLOR = '#ffffff';

export const CustomLink = ({ l }) => {
  if (l.external) {
    return <a href={l.link}>{l.name}</a>;
  } else {
    return <Link to={l.link}>{l.name}</Link>;
  }
};

export const menu = [
  {
    name: 'About',
    link: '/about',
    external: false,
  },
  {
    name: 'Branches',
    link: '/branches',
    external: false,
  },
  {
    name: 'Events',
    link: '/events',
    external: false,
  },
  {
    name: 'Blog',
    link: '/blog',
    external: false,
  },
  {
    name: 'Contact',
    link: '/contact',
    external: false,
  },
  {
    name: 'Book',
    link: '/book',
    external: false,
    submenu: [
      {
        name: 'Book A Tour',
        link: '/book-tour',
        external: false,
      },
      {
        name: 'Book Meeting Room',
        link: '/book-meeting',
        external: false,
      },
      {
        name: 'Book Event Hall',
        link: '/book-event',
        external: false,
      },
    ],
  },
];

export const ANALYTICS_ID = process.env.REACT_APP_ANALYTICS_ID;

export const PIXEL_ID = process.env.REACT_APP_PIXEL_ID;

/** @jsx jsx */
import { jsx } from '@emotion/core';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { mqMax, mqMin } from '../../constants';

export default function ({
  title = 'Premesis',
  subTitle = 'Professional Work Environment',
  image = 'https://s3.ap-southeast-1.amazonaws.com/app.popcornstudio.co/images/1614256201310-DSC00503-HDR.jpg',
  data = [],
}) {
  return (
    <Box component='section' className='infographics'>
      <Container
        maxWidth='lg'
        css={{
          display: 'flex',
          flexDirection: 'column',
          WebkitBoxAlign: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          css={{
            width: '100%',
            height: '100%',
          }}
        >
          <Box
            css={{
              display: 'block',
              margin: 'unset',
              padding: '70px 0px 70px 0px',
              [mqMax[0]]: {
                padding: '55px 0px 0px',
              },
            }}
          >
            <Box
              css={{
                display: 'grid',
                gridAutoFlow: 'row',
                gap: '16px',
                marginBottom: 48,
                [mqMax[0]]: {
                  marginBottom: 32,
                },
              }}
            >
              <Box
                component='h2'
                className='title'
                css={{
                  fontWeight: '600',
                  lineHeight: '60px',
                  margin: '0px',
                  padding: '0px',
                  textAlign: 'center',
                  [mqMax[0]]: {
                    fontSize: '24px',
                    lineHeight: '36px',
                  },
                  zIndex: 1,
                }}
              >
                {title}
              </Box>
              <Box
                component='h5'
                css={{
                  fontSize: '20px',
                  color: '#3f3836',
                  fontWeight: '400',
                  lineHeight: '30px',
                  margin: '0px',
                  padding: '0px',
                  textAlign: 'center',
                  zIndex: 1,
                  [mqMax[0]]: {
                    lineHeight: '36px',
                  },
                }}
              >
                {subTitle}
              </Box>
            </Box>
            <Box
              css={{
                display: 'grid',
                gap: '24px 56px',
                gridTemplateColumns: 'repeat(auto-fit, minmax(270px, 1fr))',
                [mqMax[0]]: {
                  gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))',
                },
                [mqMin[1]]: {
                  rowGap: '50px',
                },
              }}
              component='section'
            >
              {data?.map((d, i) => (
                <Box css={{ display: 'flex', flexDirection: 'column' }} key={i}>
                  <Box
                    component='span'
                    css={{
                      color: 'white',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      lineHeight: '79px',
                      marginBottom: '10px',
                      fontSize: '64px',
                      textAlign: 'center',
                      WebkitTextFillColor: 'transparent',
                      backgroundImage:
                        'linear-gradient(109.88deg, #ff8f13 0%, #ff8f13 100%)',
                      WebkitBackgroundClip: 'text',
                      MozBackgroundClip: 'text',
                      MozTextFillColor: 'transparent',
                      zIndex: 1,
                    }}
                  >
                    {d.label}
                  </Box>
                  <Box
                    css={{
                      color: '#3f3836',
                      fontWeight: '300',
                      fontSize: '20px',
                      lineHeight: '150%',
                      textAlign: 'center',
                      [mqMax[0]]: {
                        fontSize: '18px',
                        lineHeight: '27px',
                      },
                      zIndex: 1,
                    }}
                  >
                    {d.value}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

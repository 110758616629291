import React from 'react';
import { Formik, ErrorMessage } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { sendInquiry } from '../../redux/actions';

export default ({ pattern = false }) => {
  const { setting } = useSelector(({ settings }) => settings);
  const { response } = useSelector(({ home }) => home);
  const dispatch = useDispatch();

  const initialInquiry = {
    subject: '',
    message: '',
    webData: {
      name: '',
      email: '',
      phone: '',
    },
  };

  const InquirySchema = Yup.object().shape({
    subject: Yup.string().required('Required'),
    message: Yup.string(),
    webData: Yup.object().shape({
      name: Yup.string().required('Required'),
      email: Yup.string().email('Invalid Email').required('Required'),
      phone: Yup.string().required('Required'),
    }),
  });

  return (
    <section className='write-massage-area mb-3'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6'>
            <div className='write-massage-content'>
              <div className='write-massage-title'>
                <h3 className='title'>Write us a message.</h3>
              </div>
              <Formik
                enableReinitialize={true}
                initialValues={initialInquiry}
                validationSchema={InquirySchema}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(false);
                  dispatch(sendInquiry(values, 'contact'));
                }}
              >
                {({
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className='write-massage-input'>
                      {response && (
                        <div className='row'>
                          <div className='col-lg-12'>
                            <p class='text-success'>{response}</p>
                          </div>
                        </div>
                      )}
                      <div className='row'>
                        <div className='col-lg-6'>
                          <div className='input-box mt-10'>
                            <input
                              type='text'
                              placeholder='Name'
                              name='webData.name'
                              value={values.webData.name}
                              onChange={handleChange}
                            />
                            <ErrorMessage name='webData.name' />
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <div className='input-box mt-10'>
                            <input
                              name='webData.email'
                              type='email'
                              placeholder='Email'
                              value={values.webData.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <ErrorMessage name='webData.email' />
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <div className='input-box mt-10'>
                            <input
                              type='tel'
                              placeholder='Phone'
                              name='webData.phone'
                              value={values.webData.phone}
                              onChange={handleChange}
                            />
                            <ErrorMessage name='webData.phone' />
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <div className='input-box mt-10'>
                            <input
                              type='text'
                              placeholder='Subject'
                              name='subject'
                              value={values.subject}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <ErrorMessage name='subject' />
                          </div>
                        </div>
                        <div className='col-lg-12'>
                          <div className='input-box mt-10'>
                            <textarea
                              cols='30'
                              rows='10'
                              placeholder='Message'
                              name='message'
                              value={values.message}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            ></textarea>
                            <button
                              type='submit'
                              className='main-btn main-btn-2'
                              disabled={isSubmitting}
                            >
                              Send message
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
          <div className='col-lg-6'>
            {setting && (
              <div
                className='write-massage-images animated wow fadeInRight'
                data-wow-duration='1500ms'
                data-wow-delay='0ms'
              >
                <div className='thumb d-flex justify-content-between'>
                  <div className='item-1'>
                    <img
                      src={
                        setting.contactFormPic1
                          ? setting.contactFormPic1
                          : '/assets/images/write-massage-1.jpg'
                      }
                      alt='image1'
                    />
                  </div>
                  <div className='item-2'>
                    <img
                      src={
                        setting.contactFormPic2
                          ? setting.contactFormPic2
                          : '/assets/images/write-massage-2.jpg'
                      }
                      alt='image2'
                    />
                  </div>
                </div>
                <div className='thumb mt-15'>
                  <img
                    src={
                      setting.contactFormPic3
                        ? setting.contactFormPic3
                        : '/assets/images/write-massage-3.jpg'
                    }
                    alt='image3'
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {pattern && (
        <div className='shape-pattern'>
          <img src='/assets/images/we-know-line.png' alt='pattern' />
        </div>
      )}
    </section>
  );
};

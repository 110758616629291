/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import BookTourDialog from '../BookTourDialog';
import ChoosePricingPlan from './choose';

export default ({ enablePricingPlan = true }) => {
  const { branch, branches } = useSelector(({ settings }) => settings);
  const [bookDialog, setBookDialog] = useState(false);
  const [offering, setOffering] = useState(null);

  const formatCash = (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K';
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M';
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B';
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T';
  };

  return (
    <section
      className='pricing-area pricing-page'
      style={{ paddingTop: enablePricingPlan ? 'inherit' : 120 }}
    >
      {enablePricingPlan && <ChoosePricingPlan />}
      {branches && branch && (
        <div className='pricing-item-area mt-2'>
          <div className='container'>
            <div className='row justify-content-center'>
              {branch.packages &&
                branch.packages.length > 0 &&
                branch.packages.map((pkg) => (
                  <div
                    key={pkg._id}
                    className='col-lg-4 col-md-7 col-sm-9 mb-5'
                  >
                    <div className='pricing-item text-center h-100'>
                      <span>{pkg.title}</span>
                      <h3 className='title'>PKR {formatCash(pkg.rate)}</h3>
                      {pkg.features.length === 0 && (
                        <img
                          src={pkg.image}
                          alt={pkg.title}
                          className='img-fluid'
                        />
                      )}
                      {pkg.features && pkg.features.length > 0 && (
                        <div className='list'>
                          <ul>
                            {pkg.features.map((feature, index) => (
                              <li key={index}>
                                <span>{feature}</span>{' '}
                                <i className='fa fa-check'></i>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                      <button
                        className='main-btn main-btn-2 my-2 text-white'
                        css={{
                          lineHeight: '36px',
                          padding: '0 20px',
                          fontSize: '14px',
                        }}
                        onClick={() => {
                          setOffering(pkg._id);
                          setBookDialog(true);
                        }}
                      >
                        Book space
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
      <BookTourDialog
        open={bookDialog}
        onClose={() => setBookDialog(false)}
        initial={offering}
      />
    </section>
  );
};

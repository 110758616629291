import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import PageHeader from '../../components/PageHeader';
import QuickHelp from '../../components/QuickHelp';
import BranchSelector from '../../components/BranchSelector';

import * as moment from 'moment';

import { getBranchOfferings, sendInquiry } from '../../redux/actions';
import { useQuery } from '../../constants';

function BookTour() {
  const id = useQuery().get('id');
  const initialInquiry = {
    message: '',
    webData: {
      name: '',
      email: '',
      phone: '',
      date: moment().format('yyyy-MM-DD'),
      time: moment().format('hh:mm'),
      companySize: '',
    },
  };
  const dispatch = useDispatch();
  const { branch, offerings } = useSelector(({ settings }) => settings);
  const {
    response,
    loading,
    offerings: generalOfferings,
  } = useSelector(({ home }) => home);
  const [errors, setErrors] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [inquiry, setInquiry] = useState(initialInquiry);
  const [offering, setOffering] = useState(null);
  const radioGroupRef = useRef(null);

  useEffect(() => {
    if (!branch) return;
    if (branch !== null) {
      dispatch(getBranchOfferings(branch._id));
    }
  }, [branch, dispatch]);

  useEffect(() => {
    if (!id) return;
    setOffering(id);
  }, [id]);

  function validateEmail(email) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  }

  const send = () => {
    let message = inquiry?.message;
    const webData = inquiry?.webData;
    const res =
      offerings?.find((x) => x._id === offering) ||
      generalOfferings?.find((x) => x._id === offering) ||
      branch?.packages?.find((x) => x._id === offering);
    if (message === '') {
      message = res?.title;
    }
    if (
      !webData.name ||
      !webData.email ||
      !webData.phone ||
      !webData.date ||
      !webData.time ||
      !webData.companySize ||
      !branch
    ) {
      setErrors(true);
    } else {
      setErrors(false);
      setEmailError(false);
      dispatch(
        sendInquiry({ ...inquiry, message, branch: branch._id }, 'office')
      );
      setInquiry(initialInquiry);
    }
  };

  const handleRadioChange = (event) => {
    setOffering(event.target.value);
    const res =
      offerings?.find((x) => x._id === event.target.value) ||
      generalOfferings?.find((x) => x._id === event.target.value) ||
      branch?.packages?.find((x) => x._id === event.target.value);
    setInquiry({
      ...inquiry,
      message:
        event.target.value === 'Others' ? event.target.value : res?.title,
    });
  };

  return (
    <React.Fragment>
      <PageHeader
        title='Book a Tour'
        links={[{ title: 'Home' }, { title: 'Book a Tour' }]}
      />
      <section className='write-massage-area pb-120'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8'>
              <div className='write-massage-content'>
                <div className='write-massage-title'>
                  <h3 className='title'>We'll follow up</h3>
                </div>
                <div className='write-massage-input'>
                  <div className='row'>
                    {response && (
                      <div className='col-lg-12'>
                        <small className={'text-success font-weight-bold'}>
                          {response}
                        </small>
                      </div>
                    )}
                    {errors && (
                      <div className='col-lg-12'>
                        <small className={'text-danger font-weight-bold'}>
                          Please fill out all the fields.
                        </small>
                      </div>
                    )}
                    {emailError && (
                      <div className='col-lg-12'>
                        <small className={'text-danger font-weight-bold'}>
                          Email is invalid
                        </small>
                      </div>
                    )}
                    <div className='col-lg-12 my-2'>
                      <p>Branch</p>
                      <BranchSelector variant='outlined' />
                    </div>
                    <div className='col-lg-12 my-2'>
                      <p>Inquire About</p>
                      <div className='form-check form-check-inline flex-wrap'>
                        <RadioGroup
                          row
                          ref={radioGroupRef}
                          aria-label='offering'
                          name='offering'
                          value={offering}
                          onChange={handleRadioChange}
                        >
                          {branch?.packages?.map((option) => (
                            <FormControlLabel
                              value={option._id}
                              key={option._id}
                              control={<Radio />}
                              label={option.title}
                            />
                          ))}
                          {offerings?.map((option) => (
                            <FormControlLabel
                              value={option._id}
                              key={option._id}
                              control={<Radio />}
                              label={option.title}
                            />
                          ))}
                          {generalOfferings?.map((option) => (
                            <FormControlLabel
                              value={option._id}
                              key={option._id}
                              control={<Radio />}
                              label={option.title}
                            />
                          ))}
                          <FormControlLabel
                            value={'Others'}
                            control={<Radio />}
                            label={'Others'}
                          />
                        </RadioGroup>
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='input-box mt-10'>
                        <input
                          type='text'
                          placeholder='Name'
                          value={inquiry.webData.name}
                          onChange={(e) =>
                            setInquiry({
                              ...inquiry,
                              webData: {
                                ...inquiry.webData,
                                name: e.target.value,
                              },
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='input-box mt-10'>
                        <input
                          type='email'
                          placeholder='Email'
                          value={inquiry.webData.email}
                          onChange={(e) => {
                            validateEmail(e.target.value);
                            setInquiry({
                              ...inquiry,
                              webData: {
                                ...inquiry.webData,
                                email: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='input-box mt-10'>
                        <input
                          type='tel'
                          placeholder='Phone'
                          value={inquiry.webData.phone}
                          onChange={(e) =>
                            setInquiry({
                              ...inquiry,
                              webData: {
                                ...inquiry.webData,
                                phone: e.target.value,
                              },
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='input-box mt-10'>
                        <input
                          type='number'
                          placeholder='Company Size'
                          value={inquiry.webData.companySize}
                          onChange={(e) =>
                            setInquiry({
                              ...inquiry,
                              webData: {
                                ...inquiry.webData,
                                companySize: e.target.value,
                              },
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='input-box mt-10'>
                        <input
                          placeholder='Date'
                          type='date'
                          onChange={(e) =>
                            setInquiry({
                              ...inquiry,
                              webData: {
                                ...inquiry.webData,
                                date: e.target.value,
                              },
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='input-box mt-10'>
                        <input
                          placeholder='Time'
                          type='time'
                          onChange={(e) =>
                            setInquiry({
                              ...inquiry,
                              webData: {
                                ...inquiry.webData,
                                time: e.target.value,
                              },
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className='col-lg-12'>
                      <div className='input-box mt-10'>
                        <button
                          className='main-btn main-btn-2'
                          onClick={send}
                          disabled={loading || errors || emailError}
                        >
                          Send message
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <QuickHelp />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default BookTour;

import React, { useEffect, useMemo, useState } from 'react';

import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

const useStyles = makeStyles((theme) => ({
  label: {
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.primary.main,
  },
  digit: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(0.5),
  },
  digitGrid: {
    borderRadius: 5,
    border: '1px solid #eee',
    maxWidth: 60,
    padding: theme.spacing(0.5, 1),
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  root: {
    padding: theme.spacing(1, 0),
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
}));

const TimeWidget = ({
  type,
  deadline = new Date().toString(),
  isPlaying = true,
}) => {
  const classes = useStyles();
  const parsedDeadline = useMemo(() => Date.parse(deadline), [deadline]);
  const [time, setTime] = useState(parsedDeadline - Date.now());

  useEffect(() => {
    if (!isPlaying) return;
    const interval = setInterval(() => {
      if (type === 'countdown' || type === 'duration') {
        if (Date.now() > parsedDeadline) {
          setTime(0);
        } else {
          setTime(parsedDeadline - Date.now());
        }
      } else {
        if (Date.now() > parsedDeadline) {
          setTime(Date.now() - parsedDeadline);
        } else {
          setTime(parsedDeadline - Date.now());
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [parsedDeadline, type, isPlaying]);

  return (
    <Grid container alignItems='center' className={classes.root}>
      {Object.entries({
        Days: time / DAY,
        Hours: (time / HOUR) % 24,
        Minutes: (time / MINUTE) % 60,
        Seconds: (time / SECOND) % 60,
      }).map(([label, value], index) => (
        <Grid
          item
          sm={2}
          container
          key={index}
          alignItems='center'
          justify='center'
          className={classes.digitGrid}
        >
          <Typography variant='body1' className={classes.digit}>
            {`${Math.floor(value)}`.padStart(2, '0')}
          </Typography>
          <Typography variant='body1' className={classes.label}>
            {label[0]}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default TimeWidget;

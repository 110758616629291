import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import ReactPlayer from 'react-player/lazy';
import {
  DialogContent,
  Box,
  IconButton,
  useMediaQuery,
  DialogActions,
  Button,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialog: {
    zIndex: 9999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  video: {
    position: 'relative',
  },
  close: {
    position: 'absolute',
    right: '-2%',
    top: '-6%',
  },
  paper: {
    zIndex: 9999,
  },
  actions: {
    justifyContent: 'center',
  },
}));

function VideoPopUp({
  open,
  handleClose,
  url = 'https://www.youtube.com/watch?v=ysz5S6PUM-U',
}) {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  let videoProps = {
    url,
    playing: true,
    controls: false,
    config: {
      youtube: {
        playerVars: {
          playsinline: 1,
          showinfo: 0,
          autoplay: 1,
          controls: 0,
          modestbranding: 1,
          rel: 0,
          autohide: 1,
          wmode: 'opaque',
          origin: window.location.origin,
        },
      },
    },
  };

  if (isMobile) {
    videoProps = {
      ...videoProps,
      width: '100%',
    };
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='video-popup-title'
      className={classes.dialog}
      PaperProps={{ className: classes.paper }}
      PaperComponent={'div'}
      maxWidth='md'
      fullWidth
      fullScreen={isMobile}
      scroll='paper'
    >
      <DialogContent className={classes.content}>
        <Box className={classes.video}>
          {!isMobile && (
            <IconButton
              className={classes.close}
              onClick={handleClose}
              color='primary'
            >
              <Close />
            </IconButton>
          )}
          <ReactPlayer {...videoProps} onEnded={handleClose} />
        </Box>
      </DialogContent>
      {isMobile && (
        <DialogActions className={classes.actions}>
          <Button onClick={handleClose} color='primary' variant='contained'>
            Close
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default VideoPopUp;

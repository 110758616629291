import { combineReducers } from 'redux';
import settingReducer from './settingReducer';
import homeReducer from './homeReducer';
import authReducer from './authReducer';
import blogReducer from './blogReducer';

const rootReducer = combineReducers({
    settings: settingReducer,
    home: homeReducer,
    auth: authReducer,
    blog: blogReducer
});

export default rootReducer;
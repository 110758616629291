import React from 'react';

export default ({ data, pattern = false }) => {
  return (
    <section className='services-box-area pt-30'>
      <div className='container'>
        {data && data.length > 0 && (
          <div className='row'>
            {data.map((d) => (
              <div className='col-lg-3 col-md-6 col-sm-6' key={d._id}>
                <div className='single-services mt-30'>
                  <i className={d.icon}></i>
                  <h4 className='title'>{d.title}</h4>
                  <p>{d.description}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {pattern && (
        <div className='services-pattern'>
          <img src={'/assets/images/services-pattern.png'} alt='' />
        </div>
      )}
    </section>
  );
};

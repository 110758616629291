import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";

export default () => {
  const [gallery, setGallery] = useState(false);
  const { branch } = useSelector(({ settings }) => settings);
  return (
    <div className="play-area my-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            {branch && <div className="play-thumb">
              <img src={branch.sliderImages[0]} alt="play" />
              <button className="main-btn" onClick={() => setGallery(true)}><i className="fa fa-play"></i></button>
            </div>
            }
          </div>
        </div>
      </div>
      {gallery && <Lightbox images={branch.sliderImages} onClose={() => setGallery(false)} />}
    </div>
  );
};
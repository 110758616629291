/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState } from 'react';

export default ({ whatsApp }) => {
  const [showScroll, setShowScroll] = useState(false);
  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  window.addEventListener('scroll', checkScrollTop);
  return (
    <div className='go-top-area' onClick={scrollTop}>
      <div className='go-top-wrap'>
        <div className='go-top-btn-wrap'>
          <a
            href={whatsApp}
            className='float'
            target='_blank'
            rel='noopener noreferrer'
            css={{ bottom: showScroll ? 65 : 25 }}
          >
            <i className='fa fa-whatsapp'></i>
          </a>
          <div
            className={
              showScroll ? 'go-top active go-top btn' : 'go-top go-top-btn'
            }
          >
            <i className='fa fa-angle-double-up'></i>
            <i className='fa fa-angle-double-up'></i>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getBranch, getCities } from '../../redux/actions';
import Chip from '@material-ui/core/Chip';

export default ({ title = 'Locations', pattern = false }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { branches, cities } = useSelector(({ settings }) => settings);

  const [city, setCity] = useState();
  const [category, setCategory] = useState('');

  const openBranch = (id) => {
    dispatch(getBranch(id));
    history.push('/branches');
  };

  useEffect(() => {
    dispatch(getCities());
  }, [dispatch]);

  return (
    <section className='why-codesk-area'>
      <div className='container'>
        <div className='row pt-5 pb-3'>
          <div className='col-lg-12'>
            <div className='why-codesk-title text-center'>
              <h3 className='title'>{title}</h3>
            </div>
          </div>
        </div>
        {cities && cities.length > 0 && !city && (
          <div className='row justify-content-center'>
            {cities.map((city) => (
              <div
                className='col-lg-3 col-md-4 col-sm-6'
                style={{ cursor: 'pointer' }}
                key={city._id}
              >
                <div
                  className='why-codesk-item animated wow fadeInUp text-center my-2'
                  data-wow-duration='1000ms'
                  data-wow-delay='0ms'
                  onClick={() => setCity(city)}
                >
                  <div className='why-codesk-thumb'>
                    <img
                      src={city.icon}
                      alt={city.name}
                      style={{ objectFit: 'contain', height: 150, width: 150 }}
                    />
                  </div>
                  <div className='why-codesk-content text-center'>
                    <h3 className='title'>{city.name}</h3>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        {city && (
          <div className='row justify-content-center'>
            <button className='btn btn-link' onClick={() => setCity(null)}>
              Show All
            </button>
          </div>
        )}
        {city && (
          <div className='row justify-content-center'>
            <div
              className='col-lg-4 col-md-7 col-sm-8'
              style={{ cursor: 'pointer' }}
            >
              <div
                className='why-codesk-item animated wow fadeInUp text-center my-2'
                data-wow-duration='1000ms'
                data-wow-delay='0ms'
                onClick={() => setCity(city._id)}
              >
                <div className='why-codesk-thumb'>
                  <img
                    src={city.icon}
                    alt={city.name}
                    style={{ objectFit: 'contain', height: 150, width: 150 }}
                  />
                </div>
                <div className='why-codesk-content text-center'>
                  <h3 className='title'>{city.name}</h3>
                </div>
              </div>
            </div>
          </div>
        )}
        {city && !category && (
          <div className='row justify-content-center align-items-center'>
            <Chip
              label={'Studio'}
              onClick={() => setCategory('Studio')}
              color='primary'
              style={{ marginRight: 15 }}
            />
            <Chip
              label={'Spaces'}
              onClick={() => setCategory('Spaces')}
              color='primary'
            />
          </div>
        )}
        {branches && branches.length > 0 && city && category && (
          <div className='row justify-content-center align-items-center'>
            <Chip
              label={category}
              onDelete={() => setCategory('')}
              color='primary'
            />
          </div>
        )}
        {branches && branches.length > 0 && city && category && (
          <div className='row justify-content-center'>
            {branches
              .filter((x) => x.city === city._id && x.category === category)
              .map((branch, i) => (
                <div
                  className='col-lg-4 col-md-7 col-sm-8'
                  style={{ cursor: 'pointer' }}
                  key={i}
                >
                  <div
                    className='why-codesk-item animated wow zoomIn text-center my-2'
                    data-wow-duration='1000ms'
                    data-wow-delay='0ms'
                    onClick={() => openBranch(branch._id)}
                  >
                    <div className='why-codesk-thumb'>
                      <img
                        src={branch.logo}
                        alt={branch.name}
                        style={{
                          objectFit: 'contain',
                          height: 150,
                          width: 150,
                        }}
                      />
                    </div>
                    <div className='why-codesk-content text-center'>
                      <h3 className='title'>{branch.name}</h3>
                      <p className='text-dark'>
                        <small>{branch?.address?.location}</small>
                      </p>
                      <button
                        className='btn btn-link'
                        onClick={() => openBranch(branch._id)}
                      >
                        <i className='flaticon-right-arrow'></i>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
      {pattern && (
        <div className='codesk-pattern'>
          <img src='/assets/images/services-dot.png' alt='pattern' />
        </div>
      )}
    </section>
  );
};

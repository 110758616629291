import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getEvents } from '../../redux/actions';
import EventList from '../../components/EventList';
import PageHeader from '../../components/PageHeader';
import Loader from '../../components/Loader';

function Events() {
  const dispatch = useDispatch();
  const { events, loading } = useSelector(({ settings }) => settings);
  useEffect(() => {
    dispatch(getEvents());
  }, [dispatch]);
  return (
    <React.Fragment>
      {loading && <Loader />}
      <PageHeader
        title='Events'
        links={[{ title: 'Home' }, { title: 'Events' }]}
      />
      {events && <EventList data={events} />}
    </React.Fragment>
  );
}

export default Events;

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPost, getPostCategoriess, getPosts } from '../../redux/actions';
import { Link, useParams } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import Loader from '../../components/Loader';

function Post() {
  let { slug } = useParams();
  const dispatch = useDispatch();
  const { post, posts, loading, categories } = useSelector(({ blog }) => blog);

  useEffect(() => {
    dispatch(getPost(slug));
    dispatch(getPostCategoriess());
  }, [dispatch, slug]);

  useEffect(() => {
    if (post) {
      dispatch(getPosts({ tags: post.tags, id: post._id }));
    }
  }, [dispatch, post]);

  return (
    <React.Fragment>
      <PageHeader title='Blog' />
      {loading && <Loader />}
      {post && (
        <section className='blog-details'>
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-lg-8'>
                <div className='blog-details__main'>
                  <div className='blog-details__image'>
                    <img src={post.cover} alt='thumb' />
                  </div>
                  <div className='blog-details__content'>
                    <div className='blog-one__meta'>
                      {post.author && (
                        <a href='/'>
                          <i className='fa fa-user-o'></i> by {post.author.name}
                        </a>
                      )}
                      {post.category && (
                        <a href='/'>
                          <i className='fa fa-list-ul'></i> {post.category.name}
                        </a>
                      )}
                    </div>
                    <h3>{post.title}</h3>
                    <div dangerouslySetInnerHTML={{ __html: post.content }} />
                  </div>
                  <div className='blog-details__meta'>
                    {post.tags && post.tags.length > 0 && (
                      <div className='blog-details__tags'>
                        <span>Tags: </span> {post.tags.join(',')}
                      </div>
                    )}
                    <div className='blog-details__share'>
                      <ul>
                        <li>
                          <a href='/'>
                            <i className='fa fa-facebook-square'></i>
                          </a>
                        </li>
                        <li>
                          <a href='/'>
                            <i className='fa fa-instagram'></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                {post.author && (
                  <div className='blog-author'>
                    {post.author.public.picture && (
                      <div className='blog-author__image'>
                        <img
                          src={post.author.public.picture}
                          alt={post.author.name}
                          style={{
                            width: 80,
                            height: 80,
                            objectFit: 'contain',
                          }}
                        />
                      </div>
                    )}
                    <div className='blog-author__content'>
                      <h3>{post.author.name} </h3>
                    </div>
                  </div>
                )}
              </div>

              <div className='col-lg-4 col-md-6 col-sm-7'>
                <div className='sidebar'>
                  {categories && categories.length > 0 && (
                    <div className='sidebar__single sidebar__category'>
                      <h3 className='sidebar__title'>Categories</h3>
                      <ul className='sidebar__category-list'>
                        {categories.map((category) => (
                          <li
                            className='sidebar__category-list-item'
                            key={category._id}
                          >
                            <Link to={`/blog/${category.slug}`}>
                              {category.name}{' '}
                              <i className='fa fa-angle-right'></i>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  {post.tags &&
                    post.tags.length > 0 &&
                    posts &&
                    posts.length > 0 && (
                      <div className='sidebar__single sidebar__post'>
                        <h3 className='sidebar__title'>Related Posts</h3>
                        <div className='sidebar__post-wrap'>
                          {posts.map((p) => (
                            <div className='sidebar__post__single' key={p._id}>
                              <div className='sidebar__post-image'>
                                <div className='inner-block'>
                                  <img src={p.cover} alt={`cover-${p._id}`} />
                                </div>
                              </div>
                              <div className='sidebar__post-content'>
                                <h4 className='sidebar__post-title'>
                                  <Link
                                    to={`/blog/${p.category.slug}/${p.slug}`}
                                  >
                                    {p.title}
                                  </Link>
                                </h4>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  {post.tags && post.tags.length > 0 && (
                    <div className='sidebar__single sidebar__tags'>
                      <h3 className='sidebar__title'>Tags</h3>
                      <ul className='sidebar__tags-list'>
                        {post.tags.map((t, i) => (
                          <li className='sidebar__tags-list-item' key={i}>
                            {t}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </React.Fragment>
  );
}

export default Post;

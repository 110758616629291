import React from "react";

export default () => (
  <div className="preloader">
    <div className="lds-ellipsis">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
);


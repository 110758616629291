import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TimeWidget from './widget';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grid: {
    padding: theme.spacing(0.2),
  },
  text: {
    flexGrow: 1,
    color: 'white',
    fontWeight: theme.typography.fontWeightBold,
  },
  countdown: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  textDiv: {
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1, 0),
    },
  },
}));

export default function CountdownBar({
  text = 'Popping Soon',
  date = new Date(),
  buttonText = 'Learn more',
  buttonLink = '',
}) {
  const classes = useStyles();
  const history = useHistory();

  const onButtonClick = () => {
    if (!buttonLink || buttonLink === '') return;
    if (
      buttonLink.startsWith('/') ||
      buttonLink?.includes('popcornstudio.co')
    ) {
      if (buttonLink.startsWith('/')) {
        history.push(buttonLink);
        return;
      }
      if (buttonLink.includes('popcornstudio.co')) {
        let url = buttonLink.split('popcornstudio.co')[1];
        history.push(url);
        return;
      }
    } else {
      window.open(buttonLink);
    }
  };

  return (
    <div className={classes.root}>
      <AppBar position='relative'>
        <Toolbar>
          <Grid
            container
            className={classes.grid}
            alignItems='center'
            justify='space-between'
          >
            <Grid item sm={4} md={4} xs={12} className={classes.countdown}>
              <TimeWidget deadline={date.toString()} type='countdown' />
            </Grid>
            <Grid
              item
              sm={4}
              md={4}
              align='center'
              xs={6}
              className={classes.textDiv}
            >
              <Typography className={classes.text}>{text}</Typography>
            </Grid>
            <Grid
              item
              sm={4}
              md={4}
              align='end'
              xs={6}
              className={classes.textDiv}
            >
              <button
                className='main-btn main-btn-2 main-btn-small border-0'
                onClick={onButtonClick}
              >
                {buttonText}
              </button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}

/** @jsx jsx */
import { jsx } from '@emotion/core';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export default ({ data, pattern = false }) => {
  return (
    <section>
      {data && data.length > 0 && (
        <Carousel
          responsive={responsive}
          arrows={false}
          showDots={true}
          autoPlay={true}
          infinite={true}
        >
          {data.map((slide) => (
            <div className='banner-slide banner-slide-3' key={slide._id}>
              <div
                className='banner-3-area bg_cover d-flex align-items-center'
                style={{
                  backgroundImage: `url("/assets/images/banner-base.png")`,
                }}
              >
                <div className='container'>
                  <div className='row'>
                    <div className='col-lg-4'>
                      <div className='banner-content'>
                        <span>
                          <img
                            src={'/assets/images/banner-icon.png'}
                            alt='logo'
                          />{' '}
                          {slide.caption}
                        </span>
                        <h1 className='title'>{slide.title}</h1>
                        <Link className='main-btn main-btn-2' to={slide.link}>
                          {slide.linkLabel}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {pattern && (
                  <div className='banner-line'>
                    <img src={'/assets/images/banner-line.png'} alt='line' />
                  </div>
                )}
                {pattern && (
                  <div className='banner-dot'>
                    <img src={'/assets/images/banner-dot.png'} alt='line' />
                  </div>
                )}
                <div className='banner-thumb'>
                  <img
                    src={slide.media}
                    alt='line'
                    style={{ width: '100%', height: '100%' }}
                  />
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      )}
    </section>
  );
};

import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  Grid,
  DialogTitle,
  TextField,
  DialogActions,
  Button,
} from '@material-ui/core';
import axios from 'axios';
import AlertBox from '../../components/AlertBox';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  function sendResetLink() {
    setLoading(true);
    setError('');
    axios
      .post('/forgot-password', { email })
      .then((res) => {
        setSuccess(res.data.message);
        setLoading(false);
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'Something went wrong');
        setLoading(false);
      });
  }

  return (
    <>
      <div
        style={{
          minHeight: '100vh',
          backgroundImage: `url("/bg.png")`,
          backgroundRepeat: 'repeat',
          backgroundSize: 'contain',
        }}
      ></div>
      <Dialog open maxWidth='xs' fullWidth onClose={() => {}}>
        <DialogTitle>
          {success ? 'Success' : 'Please enter you email'}
        </DialogTitle>
        <DialogContent>
          {!success && (
            <Grid container spacing={2}>
              <Grid container item>
                <TextField
                  type='email'
                  id='email'
                  variant='outlined'
                  fullWidth
                  label='Your email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
            </Grid>
          )}
          {error && <AlertBox type='danger' message={error} />}
          {success && <AlertBox type='success' message={success} />}
        </DialogContent>
        {!success && (
          <DialogActions>
            <Button
              onClick={() => sendResetLink()}
              disabled={loading}
              variant='contained'
              color='primary'
            >
              Send
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
}

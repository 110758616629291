import React from 'react';
import { Link } from 'react-router-dom';

export default ({ title = 'PopCorn Studio gives you unmatched agility without compromising on experience.', link = '/branches', linkLabel = 'Book a tour', url = 'https://popcornstudio.co/wp-content/uploads/2019/10/67698062_2642512219092401_1436056907084201984_n-1.jpg' }) => {
  return (
    <section className="brand-area bg_cover" style={{ backgroundImage: `url('${url}')` }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-9">
            <div className="brand-content text-center">
              <h3 className="title">{title}</h3>
              <Link className="main-btn" to={link}>{linkLabel}</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
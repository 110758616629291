import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export default function () {
  const { setting } = useSelector(({ settings }) => settings);
  return (
    <section className="get-to-know-area">
      {setting &&
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="get-to-know-thumb mr-70 animated wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">
                <img src={setting.aboutUsPic ? setting.aboutUsPic : "/assets/images/get-to-know-thumb.jpg"} alt="pic" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="get-to-know-content">
                {setting.aboutUsTitle && <h3 className="title">{setting.aboutUsTitle}</h3>}
                {setting.aboutUsInfo && <p>{setting.aboutUsInfo}</p>}
                {setting.aboutUsFeatures && setting.aboutUsFeatures.length > 0 &&
                  <ul>
                    {
                      setting.aboutUsFeatures.map((f, i) => (
                        <li key={i}><i className="fa fa-check"></i> {f}</li>
                      ))
                    }
                  </ul>
                }
                <Link className="main-btn main-btn-2" to={'/book-tour'}>Book A Tour</Link>
              </div>
            </div>
          </div>
        </div>
      }
      <div className="about-pattern">
        <img src="/assets/images/shape-pattern.png" alt="" />
      </div>
    </section>
  );
}
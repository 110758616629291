import React from 'react';
import { useSelector } from 'react-redux';
import PageHeader from '../../components/PageHeader';
import Loader from '../../components/Loader';
import Clients from '../../components/Clients';

function ClientsPage() {
  const { loading, setting } = useSelector(({ settings }) => settings);
  return (
    <React.Fragment>
      {loading && <Loader />}
      <PageHeader
        title='Clients'
        links={[{ title: 'Home' }, { title: 'Clients' }]}
      />
      {setting?.clients?.length > 0 && <Clients data={setting?.clients} />}
    </React.Fragment>
  );
}

export default ClientsPage;

import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getGeneralOfferings, getMenus, getSettings } from './redux/actions';
import WOW from 'wowjs';
import Routes from './routes';
import Header from './components/Header';
import Footer from './components/Footer';
import BackToTop from './components/BackToTop';
import ScrollToTop from './components/ScrollToTop';

function App() {
  const authScreens = useRouteMatch([
    '/forgot-password',
    '/reset-password/:id/:code',
    '/careers-test',
  ]);
  const careerPages = useRouteMatch(['/careers-test', '/careers']);
  const dispatch = useDispatch();
  const { header, footer1, footer2, footer3, setting } = useSelector(
    ({ settings }) => settings
  );
  useEffect(() => {
    dispatch(getMenus());
    dispatch(getSettings());
    dispatch(getGeneralOfferings());
    new WOW.WOW({
      offset: 150, // distance to the element when triggering the animation (default is 0)
      mobile: true, // trigger animations on mobile devices (default is true)
      live: true, // act on asynchronously loaded content (default is true)
    }).init();
  }, [dispatch]);

  const createWhatsAppUrl = () => {
    let baseUrl = 'https://wa.me';
    if (setting?.whatsAppUrl) return setting?.whatsAppUrl;
    // if (setting?.phone?.startsWith('0')) {
    //   return `${baseUrl}/0092${setting?.phone}`;
    // }
    return `${baseUrl}/${setting?.phone}`;
  };

  return (
    <ScrollToTop>
      {!authScreens && header && (
        <Header menu={header?.links} setting={setting} />
      )}
      <Routes />
      {!authScreens && footer1 && (
        <Footer
          footer1={footer1}
          footer2={footer2}
          footer3={footer3}
          setting={setting}
        />
      )}
      {!careerPages && <BackToTop whatsApp={createWhatsAppUrl()} />}
    </ScrollToTop>
  );
}

export default App;

import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from './pages/Home';
import Branches from './pages/Branches';
import Blog from './pages/Blog';
import Post from './pages/Blog/post';
import BookTour from './pages/BookTour';
import BookMeeting from './pages/BookMeeting';
import BookEvent from './pages/BookEvent';
import Contact from './pages/Contact';
import Events from './pages/Events';
import Event from './pages/Events/single';
import About from './pages/About';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Careers from './pages/Careers';
import Team from './pages/Team';
import Gallery from './pages/Gallery';
import ClientsPage from './pages/Clients';
import PrivacyPolicy from './pages/PrivacyPolicy';

function Routes() {
  return (
    <Switch>
      <Route path='/' exact>
        <Home />
      </Route>
      <Route path='/branches' exact>
        <Branches />
      </Route>
      <Route path={['/blog', '/blog/:category']} exact>
        <Blog />
      </Route>
      <Route path='/blog/:category/:slug' exact>
        <Post />
      </Route>
      <Route path='/events' exact>
        <Events />
      </Route>
      <Route path='/events/:id' exact>
        <Event />
      </Route>
      <Route path='/about' exact>
        <About />
      </Route>
      <Route path='/contact' exact>
        <Contact />
      </Route>
      <Route path='/book-tour' exact>
        <BookTour />
      </Route>
      <Route path='/book-meeting' exact>
        <BookMeeting />
      </Route>
      <Route path='/book-event' exact>
        <BookEvent />
      </Route>
      <Route path='/forgot-password' exact>
        <ForgotPassword />
      </Route>
      <Route path='/reset-password/:id/:code' exact>
        <ResetPassword />
      </Route>
      <Route path='/careers' exact>
        <Careers />
      </Route>
      <Route path='/team' exact>
        <Team />
      </Route>
      <Route path='/gallery' exact>
        <Gallery />
      </Route>
      <Route path='/clients' exact>
        <ClientsPage />
      </Route>
      <Route path='/privacy-policy' exact>
        <PrivacyPolicy />
      </Route>
    </Switch>
  );
}

export default Routes;

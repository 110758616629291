import {
  GETSETTING_SUCCESS,
  GETSETTING_FAIL,
  GETSETTING_ATTEMPT,
  GETMENU_ATTEMPT,
  GETMENU_SUCCESS,
  GETMENU_FAIL,
  GETBRANCHES_ATTEMPT,
  GETBRANCHES_SUCCESS,
  GETBRANCHES_FAIL,
  GETBRANCH_ATTEMPT,
  GETBRANCH_SUCCESS,
  GETBRANCH_FAIL,
  CLEARBRANCH,
  GETEVENTS_ATTEMPT,
  GETEVENTS_SUCCESS,
  GETEVENTS_FAIL,
  GETEVENT_ATTEMPT,
  GETEVENT_SUCCESS,
  GETEVENT_FAIL,
  GETBRANCHOFFERINGS_ATTEMPT,
  GETBRANCHOFFERINGS_SUCCESS,
  GETBRANCHOFFERINGS_FAIL,
  GETMEETINGSLOTS_ATTEMPT,
  GETMEETINGSLOTS_SUCCESS,
  GETMEETINGSLOTS_FAIL,
  GETEVENTSLOTS_ATTEMPT,
  GETEVENTSLOTS_SUCCESS,
  GETEVENTSLOTS_FAIL,
  BOOKMEETING_ATTEMPT,
  BOOKMEETING_SUCCESS,
  BOOKMEETING_FAIL,
  RESETBOOKING,
  BOOKEVENT_ATTEMPT,
  BOOKEVENT_SUCCESS,
  BOOKEVENT_FAIL,
  GETCITIES_ATTEMPT,
  GETCITIES_SUCCESS,
  GETCITIES_FAIL,
} from '../constants';

const initialState = {
  loading: false,
  slotLoading: false,
  error: null,
  setting: null,
  header: null,
  footer1: null,
  footer2: null,
  footer3: null,
  branches: null,
  branch: null,
  events: null,
  offerings: null,
  cities: null,
  slots: null,
  eventSlots: null,
  bookingMessage: null,
  bookingLoading: false,
  bookingStatus: null,
  careerForm: 'SiqAg8t0',
};

export default function settingReducer(state = initialState, action) {
  switch (action.type) {
    case GETSETTING_ATTEMPT:
      return { ...state, loading: true };
    case GETSETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        setting: action.payload.setting,
        careerForm: action.payload.setting?.careerForm,
      };
    case GETSETTING_FAIL:
      return { ...state, loading: false, error: action.payload };
    case GETMENU_ATTEMPT:
      return { ...state, loading: true };
    case GETMENU_SUCCESS:
      return {
        ...state,
        loading: false,
        header: action.payload.find((m) => m.position === 'header'),
        footer1: action.payload.find((m) => m.position === 'footer1'),
        footer2: action.payload.find((m) => m.position === 'footer2'),
        footer3: action.payload.find((m) => m.position === 'footer3'),
      };
    case GETMENU_FAIL:
      return { ...state, loading: false, error: action.payload };
    case GETBRANCHES_ATTEMPT:
      return { ...state, loading: true, branches: null };
    case GETBRANCHES_SUCCESS:
      return { ...state, loading: false, branches: action.payload };
    case GETBRANCHES_FAIL:
      return { ...state, loading: false, error: action.payload };
    case GETBRANCH_ATTEMPT:
      return { ...state, loading: true, branch: null };
    case GETBRANCH_SUCCESS:
      return { ...state, loading: false, branch: action.payload };
    case CLEARBRANCH:
      return { ...state, branch: null };
    case GETBRANCH_FAIL:
      return { ...state, loading: false, error: action.payload };
    case GETEVENTS_ATTEMPT:
      return { ...state, loading: true, events: null };
    case GETEVENTS_SUCCESS:
      return { ...state, loading: false, events: action.payload };
    case GETEVENTS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case GETEVENT_ATTEMPT:
      return { ...state, loading: true, event: null };
    case GETEVENT_SUCCESS:
      return { ...state, loading: false, event: action.payload.data };
    case GETEVENT_FAIL:
      return { ...state, loading: false, error: action.payload };
    case GETBRANCHOFFERINGS_ATTEMPT:
      return { ...state, loading: true };
    case GETBRANCHOFFERINGS_SUCCESS:
      return { ...state, loading: false, offerings: action.payload };
    case GETBRANCHOFFERINGS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case GETMEETINGSLOTS_ATTEMPT:
      return { ...state, slotLoading: true };
    case GETMEETINGSLOTS_SUCCESS:
      return { ...state, slotLoading: false, slots: action.payload };
    case GETMEETINGSLOTS_FAIL:
      return { ...state, slotLoading: false, error: action.payload };
    case GETEVENTSLOTS_ATTEMPT:
      return { ...state, loading: true };
    case GETEVENTSLOTS_SUCCESS:
      return { ...state, loading: false, eventSlots: action.payload };
    case GETEVENTSLOTS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case BOOKMEETING_ATTEMPT:
      return { ...state, bookingLoading: true };
    case BOOKMEETING_SUCCESS:
      return {
        ...state,
        bookingLoading: false,
        bookingMessage: action.payload.message,
        bookingStatus: action.payload.status,
      };
    case BOOKMEETING_FAIL:
      return { ...state, bookingLoading: false, error: action.payload };
    case BOOKEVENT_ATTEMPT:
      return { ...state, bookingLoading: true };
    case BOOKEVENT_SUCCESS:
      return {
        ...state,
        bookingLoading: false,
        bookingMessage: action.payload.message,
        bookingStatus: action.payload.status,
      };
    case BOOKEVENT_FAIL:
      return { ...state, bookingLoading: false, error: action.payload };
    case RESETBOOKING:
      return {
        ...state,
        bookingMessage: null,
        bookingStatus: null,
        slots: null,
        eventSlots: null,
      };
    case GETCITIES_ATTEMPT:
      return { ...state, loading: true };
    case GETCITIES_SUCCESS:
      return { ...state, loading: false, cities: action.payload };
    case GETCITIES_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}

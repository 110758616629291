/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getBranches } from '../../redux/actions';
import { jsx } from '@emotion/core';
import SideMenu from './SideMenu';
import BranchSelector from '../BranchSelector';

import Map from '../../assets/svg/map.svg';
import Mail from '../../assets/svg/mail.svg';
import Phone from '../../assets/svg/phone.svg';
import CountdownBar from '../CountdownBar';

export default function ({ menu, setting }) {
  const dispatch = useDispatch();
  const { branch } = useSelector(({ settings }) => settings);
  const [menuState, setMenuState] = useState('');
  useEffect(() => {
    dispatch(getBranches());
  }, [dispatch]);
  return (
    <React.Fragment>
      <div className={'off_canvars_overlay ' + menuState}></div>
      <SideMenu
        menuState={menuState}
        setMenuState={setMenuState}
        menu={menu}
        setting={setting}
      />
      {setting?.enableCountdown && (
        <CountdownBar
          date={setting?.countdownDate}
          text={setting?.countdownText}
          buttonText={setting?.countdownButtonText}
          buttonLink={setting?.countdownButtonLink}
        />
      )}
      <header className='header-area header-2-area header-3-area'>
        {setting && (
          <div className='header-top-area header-top-3-area'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='header-top'>
                    <ul>
                      <li>
                        <a href={`mailto:${setting.email}`}>
                          <img src={Mail} alt='mail-icon' />
                          {setting.email}
                        </a>
                      </li>
                      <li>
                        <a href={`tel:${setting.phone}`}>
                          <img src={Phone} alt='phone-icon' />
                          {setting.phone}
                        </a>
                      </li>
                      <li>
                        <a href='/'>
                          <img src={Map} alt='map-icon' />
                          {setting.address}
                        </a>
                      </li>
                    </ul>
                    <BranchSelector variant={!branch ? 'contained' : 'text'} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          className={
            setting?.enableCountdown ? 'main-header countdown' : 'main-header'
          }
        >
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='main-header-item d-flex d-lg-block justify-content-between align-items-center'>
                  <div className='main-header-menus  d-flex justify-content-between'>
                    {setting && setting.logo && (
                      <div className='header-logo'>
                        <Link to='/'>
                          <img src={setting.logo} alt='logo' height='50' />
                        </Link>
                      </div>
                    )}
                    <div className='header-menu d-none d-lg-block'>
                      {menu && menu.length > 0 && (
                        <ul>
                          {menu.map((m, i) => (
                            <li key={i}>
                              <Link
                                className={
                                  useRouteMatch(m.link) ? 'active' : null
                                }
                                to={m.link}
                              >
                                {m.name}{' '}
                                {m.haveSubMenu && (
                                  <i className='fa fa-angle-down'></i>
                                )}
                              </Link>
                              {m.haveSubMenu &&
                                m.submenu &&
                                m.submenu.length > 0 && (
                                  <ul className='sub-menu'>
                                    {m.submenu.map((sub, j) => (
                                      <li key={j}>
                                        <Link to={sub.link}>{sub.name}</Link>
                                      </li>
                                    ))}
                                  </ul>
                                )}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className='header-social d-flex align-items-center'>
                    <div
                      className='toggle-btn ml-30 canvas_open d-lg-none d-block'
                      onClick={() => setMenuState(menuState ? '' : 'active')}
                    >
                      <i className='fa fa-bars'></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
}

import React from 'react';

export default function ({ data }) {
  return (
    <section className="team-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="team-title text-center">
              <h3 className="title">Team members</h3>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          {data.map((member) => (
            <div className="col-lg-4 col-md-7 col-sm-8" key={member._id}>
              <div className="team-item mt-30 animated wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="0ms">
                {member.picture &&
                  <div className="team-thumb">
                    <img src={member.picture} alt="team" />
                  </div>
                }
                <div className="team-content text-center">
                  <h4 className="title">{member.name}</h4>
                  {member.position && <span>{member.position}</span>}
                  <ul>
                    {member.facebook && <li><a href={member.facebook}><i className="fa fa-facebook-square"></i></a></li>}
                    {member.linkedin && <li><a href={member.linkedin}><i className="fa fa-linkedin"></i></a></li>}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="team-pattern">
        <img src="/assets/images/services-dot.png" alt="" />
      </div>
    </section>
  );
}
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

export default ({ data, pattern = false }) => {
  const [hover, setHover] = useState(false);
  return (
    <section className='news-area pb-120'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-5'>
            <div className='news-title text-center'>
              <h3 className='title'>Happenings</h3>
            </div>
          </div>
        </div>
        {data && (
          <div className='row justify-content-center'>
            {data.length > 0 &&
              data.map((d) => (
                <div className='col-lg-4 col-md-7' key={d._id}>
                  <div
                    className='mt-30 animated wow fadeInUp'
                    data-wow-duration='1000ms'
                    data-wow-delay='0ms'
                    onMouseEnter={() => setHover(d._id)}
                    onMouseLeave={() => setHover(null)}
                  >
                    <div
                      className='news-item bg_cover'
                      css={{
                        backgroundImage:
                          hover === d._id ? `url(${d.cover})` : 'none',
                      }}
                    >
                      <div className='item'>
                        {d.branch && (
                          <img
                            src={d.branch?.logo}
                            alt={d.branch?.name}
                            height='72'
                            width='72'
                          />
                        )}
                        <ul>
                          <li>
                            <i className='fa fa-calendar-o'></i>{' '}
                            {moment(d.eventStart).format('DD/MMM/YYYY')}
                          </li>
                          <li>
                            <i className='fa fa-building-o'></i>{' '}
                            {d.branch?.name}
                          </li>
                        </ul>
                      </div>
                      <h3 className='title'>{d.title}</h3>
                      <Link to={`/events/${d._id}`}>
                        <i className='flaticon-right-arrow'></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
      {pattern && (
        <div className='shape-pattern'>
          <img src='/assets/images/shape-pattern.png' alt='' />
        </div>
      )}
    </section>
  );
};
